import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import InputField from "components/ui/InputField/InputField";
import Button from "components/ui/Button/Button";
import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import { mapServerErrorsToLocal } from "utils/functions";
import { API_ENDPOINTS, STATES_IN_INDIA } from "utils/constants";
import { credentialAxios } from "utils/api-calls";

import { Form, Form2 } from "styles/common-styled-components";
import AutocompleteSearch from "components/ui/Autocomplete/Autocomplete";
import { useSelector } from "react-redux";

const AddAddress = () => {
  const { kycInfo: localKycInfo } = useSelector((state) => state.user);

  const initialErrorState = {
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
    general: "",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const fromLocation = location?.state?.fromLocation;
  const navigateTo = fromLocation || "/registration/add-signature";

  const [errorState, setErrorState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .max(40, "Address must be at most 40 characters")
      .required("Address is required"),
    address1: Yup.string()
      .max(40, "Address line 1 must be at most 40 characters")
      .required("Address line 1 is required"),
    address2: Yup.string().max(
      40,
      "Address line 2 must be at most 40 characters"
    ),
    city: Yup.string()
      .max(40, "City must be at most 40 characters")
      .required("City is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string().required("Pin Code is required"),
  });

  //// API CALL

  const handleAddAddress = (data) => {
    return credentialAxios.post(API_ENDPOINTS.addAddress, data);
  };

  const onSuccess = () => {
    setErrorState(initialErrorState);
    navigate(navigateTo);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "address",
      "address1",
      "address2",
      "city",
      "state",
      "pincode",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddAddress, {
    onSuccess,
    onError,
  });

  //// END API CALL
  return (
    <>
      <RegistrationCapsule
        fromLocation={fromLocation}
        heading={`${fromLocation ? "Edit" : "Add"} Address`}
        para={`${fromLocation ? "Edit" : "Add"} your address details`}
      >
        <Formik
          initialValues={{
            address: localKycInfo?.address || "",
            address1: localKycInfo?.address1 || "",
            address2: localKycInfo?.address2 || "",
            city: localKycInfo?.city || "",
            state: localKycInfo?.state || "",
            pincode: localKycInfo?.pincode || "",
          }}
          onSubmit={mutate}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            isValid,
            setFieldValue,
          }) => {
            return (
              <Form2 onSubmit={handleSubmit}>
                <InputField
                  label={"Address"}
                  autoFocus="true"
                  value={values.address}
                  onChange={handleChange("address")}
                  error={
                    touched.address && (errors.address || errorState.address)
                  }
                  noSpin
                />
                <InputField
                  label={"Address Line 1"}
                  value={values.address1}
                  onChange={handleChange("address1")}
                  error={
                    touched.address1 && (errors.address1 || errorState.address1)
                  }
                  noSpin
                />
                <InputField
                  label={"Address Line 2 (optional)"}
                  value={values.address2}
                  onChange={handleChange("address2")}
                  error={
                    touched.address2 && (errors.address2 || errorState.address2)
                  }
                  noSpin
                />
                <InputField
                  label={"City"}
                  value={values.city}
                  onChange={handleChange("city")}
                  error={touched.city && (errors.city || errorState.city)}
                  noSpin
                />
                <AutocompleteSearch
                  labelMapper={"title"}
                  valueMapper={"id"}
                  options={STATES_IN_INDIA}
                  label={"State"}
                  value={values.state}
                  onChange={(option) => setFieldValue("state", option.id)}
                  error={touched.state && (errors.state || errorState.state)}
                />
                <InputField
                  label={"Pincode"}
                  type="number"
                  value={values.pincode}
                  onChange={handleChange("pincode")}
                  error={
                    touched.pincode && (errors.pincode || errorState.pincode)
                  }
                  noSpin
                />
                <Button
                  title={fromLocation ? "EDIT" : "SUBMIT"}
                  type={"submit"}
                  isLoading={isLoading}
                  error={errorState.general}
                />
              </Form2>
            );
          }}
        </Formik>
      </RegistrationCapsule>
    </>
  );
};

export default AddAddress;
