import moment from "moment";

import { GENERIC_SERVER_ERROR, REG_LINKS } from "./constants";
/* eslint-disable eqeqeq */

export const getNextRegistrationScreen = (userInfo, kycInfo) => {
  const { pan, gender, occ_code, address, kycnominee, signature } = kycInfo;

  const obj = {
    emailVerified: userInfo.email_verified,
    panVerified: Boolean(pan),
    personalDetailsAdded: Boolean(gender),
    professionalDetailsAdded: Boolean(occ_code),
    nomineeAdded:
      Boolean(kycnominee ? kycnominee[0]?.id : "") ||
      (userInfo.nomination_flag && kycInfo),
    addressAdded: Boolean(address),
    bankAdded: userInfo.bankdetail
      ? Boolean(userInfo.bankdetail[0]?.account_number)
      : false,
    signatureAdded: Boolean(signature),
    kycDetailsConfirmed: userInfo.kyc_details_confirmed,
  };

  const firstFalseKey = Object.keys(obj).find((key) => !obj[key]);

  if (!userInfo.bse_account_created) {
    return firstFalseKey
      ? `/registration/${REG_LINKS[firstFalseKey]}`
      : `/registration/${REG_LINKS.kycDetailsConfirmed}`;
  }
};

export const convertToDate = (date) => {
  return `${date.toString().padStart(2, "0")}-${
    date < new Date().getDate()
      ? (new Date().getMonth() + 2).toString().padStart(2, "0")
      : (new Date().getMonth() + 1).toString().padStart(2, "0")
  }-${new Date().getFullYear()}`;
};

export const jsDateFormat = (date) => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

export const sortObjectDataByProperty = (
  data,
  order,
  propertyDataType,
  propertyName
) => {
  if (propertyDataType === "STRING" && order === "UP") {
    let temp = data;

    const filteredData = temp.sort((a, b) => {
      let fa = b[propertyName].toLowerCase(),
        fb = a[propertyName].toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return filteredData;
  }
  if (propertyDataType === "STRING" && order === "DOWN") {
    let temp = data;

    const filteredData = temp.sort((a, b) => {
      let fa = a[propertyName].toLowerCase(),
        fb = b[propertyName].toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return filteredData;
  }
  if (propertyDataType === "NUMBER" && order === "DOWN") {
    let temp = data;

    const filteredData = temp.sort((a, b) => {
      return Number(b[propertyName]) - Number(a[propertyName]);
    });

    return filteredData;
  }
  if (propertyDataType === "NUMBER" && order === "UP") {
    let temp = data;

    const filteredData = temp.sort((a, b) => {
      return Number(a[propertyName]) - Number(b[propertyName]);
    });

    return filteredData;
  }
};

export function convertNumberToCurrency(number) {
  let inr = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  return inr.format(number);
}

export function capitalizeText(text) {
  if (text) {
    return text
      .split(" ")
      .map((word) =>
        word ? `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}` : ""
      )
      .join(" ");
  }
}

export function capitalizeFundName(text) {
  const ABBREVIATIONS = [
    "BOI",
    "AXA",
    "L&T",
    "IDFC",
    "AMC",
    "SBI",
    "YES",
    "UTI",
    "DSP",
    "KMAMCL",
    "HSBC",
    "IDBI",
    "NJ",
    "BNP",
    "PPFAS",
    "PGIM",
    "LIC",
    "ITI",
    "HDFC",
    "IDCW",
    "JM",
    "ICICI",
    "BNP",
    "ELSS",
    "FOF",
    "(P.H.D)",
  ];

  if (text) {
    return text
      .split(" ")
      .map((word) => {
        if (!word) return ""; // Handle empty strings gracefully
        if (ABBREVIATIONS.includes(word.toUpperCase())) {
          return word.toUpperCase(); // Keep abbreviations uppercase
        }
        return `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`; // Capitalize the first letter
      })
      .join(" ")
      .trim(); // Remove any leading or trailing spaces
  }
  return "";
}

export function colorForValue(valueToCompare, valueToCompareWith) {
  if (valueToCompare < valueToCompareWith) {
    return "var(--errorTextColor)";
  } else if (valueToCompare > valueToCompareWith) {
    return "var(--themeColor)";
  } else {
    return "";
  }
}

export const getDateSuffix = (date) => {
  if (date == 1 || date == 21 || date == 31) {
    return "st";
  } else if (date == 2 || date == 22) {
    return "nd";
  } else if (date == 3 || date == 23) {
    return "rd";
  } else {
    return "th";
  }
};

export function secondsToMinutesAndSeconds(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return { minutes, seconds };
}

export function mapServerErrorsToLocal(
  errorObject,
  initialErrorsState = { general: "" },
  fieldsToCheck = [],
  errorMsgMap = { error: "general", non_field_errors: "general" }
) {
  const newErrors = { ...initialErrorsState };

  if (errorObject?.response?.data) {
    const { data } = errorObject.response;

    if (data.error) {
      newErrors[errorMsgMap.error] = data.error;
    } else if (data.non_field_errors && data.non_field_errors.length > 0) {
      newErrors[errorMsgMap.non_field_errors] = data.non_field_errors[0];
    } else {
      const matchingFields = fieldsToCheck.filter(
        (field) => data[field] && data[field].length > 0
      );

      if (matchingFields.length > 0) {
        matchingFields.forEach((field) => {
          newErrors[field] = data[field][0];
        });
      } else {
        newErrors.general = GENERIC_SERVER_ERROR;
      }
    }
  } else {
    newErrors.general = GENERIC_SERVER_ERROR;
  }

  return newErrors;
}

export function calculateAge(dob, dt) {
  dt = dt || new Date();
  var diff = dt.getTime() - new Date(dob).getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
}

export const dMYtoDateString = (dMY) => {
  if (dMY.includes("/")) {
    const [day, month, year] = dMY.split("/");
    return `${year}-${month}-${day}`;
  } else {
    const [day, month, year] = dMY.split("-");
    return `${year}-${month}-${day}`;
  }
};

export const DateStringtoDmy = (dateString, seperator = "/") => {
  if (dateString.includes("/")) {
    const [year, month, day] = dateString.split("/");
    return `${day}${seperator}${month}${seperator}${year}`;
  } else {
    const [year, month, day] = dateString.split("-");
    return `${day}${seperator}${month}${seperator}${year}`;
  }
};

export const mDytoDmy = (mdy) => {
  if (mdy.includes("/")) {
    const [month, day, year] = mdy.split("/");
    return `${day}/${month}/${year}`;
  } else {
    const [month, day, year] = mdy.split("-");
    return `${day}/${month}/${year}`;
  }
};

export const dateObjToDateString = (dateObj) => {
  let day = `${dateObj.getDate()}`.padStart(2, "0");
  let month = `${dateObj.getMonth() + 1}`.padStart(2, "0");
  let year = dateObj.getFullYear();

  return `${year}-${month}-${day}`;
};

export const parseDateFromString = (dateString) => {
  if (dateString.includes("/")) {
    const [day, month, year] = dateString.split("/");
    return new Date(Number(year), Number(month) - 1, Number(day));
  } else {
    const [day, month, year] = dateString.split("-");
    return new Date(Number(year), Number(month) - 1, Number(day));
  }
};

export const formatDateToString = (dateObject) => {
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};

export function calculateOneDayAbsoluteReturn(data) {
  if (data.length === 0) {
    return 0;
  } else {
    const finalValue = data[data.length - 1]?.nav_price;
    const initialValue = data[data.length - 2]?.nav_price;

    return (((finalValue - initialValue) / initialValue) * 100).toFixed(2);
  }
}

export function calculateCAGR(data, fromMonthsBefore) {
  if (data.length === 0) {
    return 0; // Return 0 when data is empty
  }
  const currentDate = moment();
  let startDate;

  // Determine start date based on time period
  if (fromMonthsBefore === "all") {
    // If fromMonthsBefore is "all", consider all data
    startDate = moment(data[0]?.date, "DD-MM-YYYY");
  } else {
    // Calculate start date based on fromMonthsBefore
    startDate = moment().subtract(fromMonthsBefore, "months");
  }

  // Filter data based on start date
  const selectedData =
    fromMonthsBefore === "all"
      ? data
      : data.filter((curr) =>
          moment(curr?.date, "DD-MM-YYYY").isAfter(startDate)
        );

  // Calculate CAGR
  const finalNAV = selectedData[selectedData.length - 1]?.nav_price;
  const initialNAV = selectedData[0]?.nav_price;

  const numberOfYears = currentDate.diff(startDate, "years", true);
  if (isNaN(finalNAV) || isNaN(initialNAV)) {
    return 0; // Return 0 when NAV price data is invalid
  }

  if (fromMonthsBefore < 12) {
    const value = (
      (Math.pow(finalNAV / initialNAV, 1 / fromMonthsBefore) - 1) *
      100
    ).toFixed(2);
    if (isNaN(value)) {
      return 0; // Return 0 when CAGR calculation fails
    }
    return parseFloat(value); // Return the CAGR value as a number
  } else {
    const value = (
      (Math.pow(finalNAV / initialNAV, 1 / numberOfYears) - 1) *
      100
    ).toFixed(2);
    if (isNaN(value)) {
      return 0; // Return 0 when CAGR calculation fails
    }
    return parseFloat(value); // Return the CAGR value as a number
  }
}
