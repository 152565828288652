import React from "react";
import styled from "styled-components";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import { HelpOutlineTwoTone } from "@mui/icons-material/";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeFundName } from "utils/functions";

const SipOrderDetails = () => {
  const location = useLocation();
  const { sipDetails, index } = location.state || {};
  const navigate = useNavigate();

  // const steps =
  //   sipDetails && sipDetails.sip_journey
  //     ? sipDetails.sip_journey.map((journey) => ({
  //         label:
  //           journey.instalment_status === "Completed"
  //             ? "Units allotted"
  //             : "Payment in progress",
  //         status: journey.instalment_status,
  //         date: new Date(journey.date).toLocaleString("en-IN", {
  //           day: "2-digit",
  //           month: "short",
  //           year: "2-digit",
  //           hour: "2-digit",
  //           minute: "2-digit",
  //           hour12: true,
  //         }),
  //         orderId: journey?.allotment_details?.order_number || "N/A",
  //         orderDate: journey?.allotment_details?.order_date
  //           ? new Date(journey.allotment_details.order_date).toLocaleDateString(
  //               "en-GB",
  //               {
  //                 day: "2-digit",
  //                 month: "short",
  //                 year: "2-digit",
  //               }
  //             )
  //           : "N/A",
  //       }))
  //     : [];

  return (
    <MainContainer style={{ display: "flex", gap: "30px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          flex: 0.7,
        }}
      >
        <Container>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "16px",
            }}
          >
            <Header>
              <LogoContainer>
                <img
                  src={sipDetails?.logo_url}
                  alt="Fund Logo"
                  style={{ width: "100%", height: "100%" }}
                />
              </LogoContainer>
              <Amounttext>₹{sipDetails?.instalment_amount || "N/A"}</Amounttext>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Title>
                  {capitalizeFundName(sipDetails?.scheme_name) ||
                    "Scheme Name Not Available"}
                </Title>
                <Label>SIP Instalment</Label>
              </div>
            </Header>
            <Amount>₹{sipDetails?.instalment_amount || "N/A"}</Amount>
          </div>

          {/* Details Section */}
          <DetailsSection>
            <DetailsRow>
              <DetailsLabel>Folio Number:</DetailsLabel>
              <DetailsValue>{sipDetails?.folio_number || "N/A"}</DetailsValue>
            </DetailsRow>
            <DetailsRow>
              <DetailsLabel>Order Number:</DetailsLabel>
              <DetailsValue>
                {sipDetails?.order_number ||
                  sipDetails.sip_journey[index].allotment_details.order_number}
              </DetailsValue>
            </DetailsRow>
            {/* <DetailsRow>
              <DetailsLabel>Order Date:</DetailsLabel>
              <DetailsValue>{sipDetails?.order_date || "N/A"}</DetailsValue>
            </DetailsRow> */}
          </DetailsSection>
        </Container>

        <HelpSection>
          <HelpText>Have questions about this order?</HelpText>
          <HelpText
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/help");
            }}
          >
            <HelpOutlineTwoTone />
            Get help
          </HelpText>
        </HelpSection>
      </div>

      <div style={{ flex: 0.3 }}>
        <StepsContainer>
          <StepsTitle>Status</StepsTitle>

          <StepWrapper>
            <Step>
              <CheckCircleTwoToneIcon
                sx={{ fontSize: "24px", color: "#20b2aa" }}
              />
            </Step>
            <StepLabel>
              <StepText>Auto payment confirmed</StepText>
              <StepDate>
                {new Date(
                  sipDetails.sip_journey[index].allotment_details.order_date
                ).toLocaleDateString()}
              </StepDate>
            </StepLabel>
          </StepWrapper>

          <div
            style={{
              height: "40px",
              borderLeft: "3px solid #20b2aa",
              margin: "0 35px",
            }}
          ></div>

          <StepWrapper>
            <Step>
              <CheckCircleTwoToneIcon
                sx={{ fontSize: "24px", color: "#20b2aa" }}
              />
            </Step>
            <StepLabel>
              <StepText>Units allocated</StepText>
              <StepDate>
                {new Date(
                  sipDetails.sip_journey[index].allotment_details.report_date
                ).toLocaleDateString()}
              </StepDate>
            </StepLabel>
          </StepWrapper>
        </StepsContainer>
      </div>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  @media (max-width: 400px) {
    margin-top: 60px;
    flex-direction: column;
  }
`;

const Container = styled.div`
  background-color: white;

  border: 1px solid #ddd;
  border-radius: 8px;
`;

const Amount = styled.p`
  font-size: 20px;

  color: #212529;
  @media (max-width: 400px) {
    display: none;
  }
`;
const LogoContainer = styled.div`
  margin-bottom: 0.8px;
  height: 4.8rem;
  width: 4.8rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  padding: 6px;
`;
const Amounttext = styled.p`
  font-size: 20px;

  color: #212529;
  @media (min-width: 400px) {
    display: none;
  }
`;
const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Label = styled.span`
  font-size: 12px;
  color: #6c757d;
`;

const Title = styled.p`
  /* font-size: 14px; */
`;

const StepsContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const StepsTitle = styled.p`
  font-size: 16px;

  border-bottom: 1px solid #ddd;

  padding: 16px;
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 16px;
`;

const Step = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const StepLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepText = styled.span`
  font-size: 14px;
  color: #212529;
`;

const StepDate = styled.span`
  font-size: 12px;
`;

const DetailsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 80px;
  padding: 16px;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailsLabel = styled.span`
  font-size: 14px;
  color: #6c757d;
`;

export const DetailsValue = styled.span`
  font-size: 14px;
  color: #212529;
`;

const HelpSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #e4faf9;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const HelpText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
`;

const StepDetail = styled.div`
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
`;
export default SipOrderDetails;
