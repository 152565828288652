import { ChevronLeft } from "@mui/icons-material";
import styled from "styled-components";
import { align_center } from "styles/common-styles";

export const StyledSelect = styled.div`
  position: relative;

  margin-bottom: 2rem;
  flex: 2;
`;

export const SelectButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--secondary-light-grey);
  border-radius: ${({ isOpen }) => (isOpen ? "4px 4px 0 0" : "4px")};
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: ${({ isOpen }) => (isOpen ? "var(--light-grey)" : "#fff")};
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowIcon = styled(ChevronLeft)`
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(270deg)")};
  transition: transform 0.3s ease;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Set your preferred max height */
  overflow-y: auto;
  border: 1px solid var(--secondary-light-grey);
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 1;
`;

export const Option = styled.div`
  padding: 12px;
  cursor: pointer;
  gap: 6px;
  ${align_center}
  &:hover {
    background-color: var(--secondary-light-grey);
  }
`;
