import React, { useState, useRef, useEffect } from "react";

import ListRenderer from "components/utility/ListRenderer";
import InputField from "components/ui/InputField/InputField";
import useOutsideClick from "hooks/useOutsideClick";

import {
  StyledSelect,
  ArrowIcon,
  OptionsContainer,
  Option,
} from "./Select.styles";
import { InputWrapper } from "../InputField/InputField.styles";
import { ErrorMessage, Label2 } from "styles/common-styled-components";

const Select = ({
  options,
  value,
  onChange = () => {},
  error,
  label,
  labelMapper = "label",
  valueMapper = "value",
  placeholder = "Select",
  openByDefault = false,
  onDropdownOpen = () => {},
  onDropdownClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const optionsContainerRef = useRef(null);
  const selectRef = useRef(null);

  // Use the useOutsideClick hook to close options when clicking outside
  useOutsideClick(selectRef, () => {
    setIsOpen(false);
    onDropdownClose();
  });

  useEffect(() => {
    if (highlightedIndex !== -1 && optionsContainerRef.current) {
      const highlightedOption =
        optionsContainerRef.current.children[highlightedIndex];
      if (highlightedOption) {
        highlightedOption.scrollIntoView({
          block: "nearest",
          inline: "nearest",
        });
      }
    }
  }, [highlightedIndex]);

  const handleSelect = (selectedValue) => {
    onChange(selectedValue);
    setIsOpen(false);
    onDropdownClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown" && highlightedIndex < options.length - 1) {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex + 1);
    } else if (e.key === "ArrowUp" && highlightedIndex > 0) {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex - 1);
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      const selectedOption = options[highlightedIndex];
      handleSelect(selectedOption[valueMapper]);
    } else if (
      e.key === "ArrowDown" &&
      highlightedIndex === options.length - 1
    ) {
      e.preventDefault();
      setHighlightedIndex(0);
    }
  };

  const handleInputFocus = () => {
    setIsOpen(true);
    onDropdownOpen();
  };

  return (
    <div>
      <InputWrapper>
        {label && <Label2>{label}</Label2>}
        <StyledSelect ref={selectRef}>
          <InputField
            onFocus={handleInputFocus}
            type="text"
            value={
              options.find((option) => option[valueMapper] === value)
                ? `${
                    options.find((option) => option[valueMapper] === value)[
                      labelMapper
                    ]
                  }${
                    options.find((option) => option[valueMapper] === value).tag
                      ? ` (${
                          options.find(
                            (option) => option[valueMapper] === value
                          ).tag
                        })`
                      : ""
                  }`
                : ""
            }
            readOnly
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            appendRight={
              <div
                onClick={() => {
                  setIsOpen(!isOpen);
                  if (isOpen) {
                    onDropdownClose();
                  } else {
                    onDropdownOpen();
                  }
                }}
                style={{
                  padding: "0 2.4px",
                  backgroundColor: "#efefef",
                  borderRadius: "4px",
                  cursor: "pointer",
                  color: "var(--themeBluishGrey)",
                }}
              >
                <ArrowIcon isOpen={isOpen} />
              </div>
            }
            style={{ cursor: "default" }}
          />

          <OptionsContainer isOpen={isOpen} ref={optionsContainerRef}>
            <ListRenderer
              items={options}
              customKeyPropName={"value"}
              renderItem={(option, index) => (
                <Option
                  onClick={() => handleSelect(option[valueMapper])}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  style={{
                    backgroundColor:
                      highlightedIndex === index
                        ? "var(--light-grey)"
                        : "transparent",
                  }}
                >
                  <span>{option[labelMapper]} </span>
                  {option.tag ? (
                    <span
                      style={{
                        backgroundColor: "var(--transparentThemeColor)",
                        color: "var(--themeColor)",
                        padding: "2px 4px",
                        borderRadius: "2px",
                        fontSize: "1.1rem",
                        fontWeight: "500",
                      }}
                    >
                      {option.tag}
                    </span>
                  ) : null}
                </Option>
              )}
            />
          </OptionsContainer>
        </StyledSelect>
      </InputWrapper>
      <ErrorMessage show={error} style={{ flex: 2 }}>
        {error}
      </ErrorMessage>
    </div>
  );
};

export default Select;
