import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import SelectBranchForm from "components/forms/SelectBranchForm";
import { Form2 } from "styles/common-styled-components";

const SelectBranch = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  useEffect(() => {
    if (!location.state?.bankId) {
      navigate("/registration/select-bank");
    }
  }, [location.state?.bankId, navigate]);

  return (
    <>
      <RegistrationCapsule
        heading={`${editMode ? "Edit" : "Add"} your Bank account`}
        para={"Select your Branch to proceed with the adding Bank."}
        fromLocation={
          editMode ? "/registration/select-bank?edit_mode=true" : ""
        }
      >
        <Form2>
          {location.state?.bankId ? (
            <SelectBranchForm
              bankId={location.state?.bankId}
              bankName={location.state?.bankName}
            />
          ) : null}
        </Form2>
      </RegistrationCapsule>
    </>
  );
};

export default SelectBranch;
