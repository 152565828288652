import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";

import Timer from "components/ui/Timer/Timer";
import Button from "components/ui/Button/Button";
import { credentialAxios, publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

import {
  ErrorMessage,
  Form2,
  StyledAlignCenter,
  StyledLink,
  UnderLineButton,
} from "styles/common-styled-components";
import styled from "styled-components";

const OtpOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;
const OtpVerification = ({
  phone_number,
  onOtpSuccess,
  onChangeNumber,
  loading,
}) => {
  const initialErrorState = { otp: "", general: "" };
  const resendInitialErrorState = { phone_number: "", general: "" };

  const NUM_INPUTS = 6;

  const [verifyOtpErrors, setVerifyOtpErrors] = useState(initialErrorState);
  const [resendOtpError, setSetResendOtpErrors] = useState(
    resendInitialErrorState
  );
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [otpValue, setOtpValue] = useState(null);

  const verifyOtp = (otp) => {
    return publicAxios.post(API_ENDPOINTS.authVerifyOtp, {
      phone_number,
      otp,
    });
  };

  const onVerifyOtpSuccess = (res) => {
    setVerifyOtpErrors(initialErrorState);
    onOtpSuccess(res.data.access, res.data.refresh);
  };

  const onVerifyOtpError = (error) => {
    const newErrors = mapServerErrorsToLocal(
      error,
      initialErrorState,
      ["otp"],
      { error: "otp" }
    );
    setVerifyOtpErrors(newErrors);
  };

  const verifyRes = useMutation(verifyOtp, {
    onError: onVerifyOtpError,
    onSuccess: onVerifyOtpSuccess,
  });

  const resendOtp = () => {
    return credentialAxios.post(API_ENDPOINTS.authSendOtp, {
      phone_number: phone_number,
    });
  };

  const onResendOtpSuccess = (res) => {};

  const onResendOtpError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, resendInitialErrorState, [
      "phone_number",
    ]);
    setSetResendOtpErrors(newErrors);
  };

  const resendRes = useMutation(resendOtp, {
    onSuccess: onResendOtpSuccess,
    onError: onResendOtpError,
  });

  const handleValidateNoOtp = (e) => {
    e.preventDefault();
    if (!otpValue) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "OTP is required",
        };
      });
      return;
    } else if (otpValue.length < 6) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "Enter all digits",
        };
      });
      return;
    }
    verifyRes.mutate(otpValue);
  };

  return (
    <>
      <Form2
        onSubmit={handleValidateNoOtp}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <OtpInput
          shouldAutoFocus={true}
          value={otpValue}
          onChange={(otp) => {
            setVerifyOtpErrors((prev) => {
              return { ...prev, otp: "" };
            });
            setOtpValue(otp);
            if (otp.length === NUM_INPUTS) {
              verifyRes.mutate(otp);
            }
          }}
          numInputs={NUM_INPUTS}
          inputStyle={{
            width: "5rem",
            height: "5rem",
            borderRadius: "6px",
            border: `1px solid ${
              verifyOtpErrors.otp ? "var(--danger)" : "#ccc"
            }`,
            fontSize: "2.4rem",
            fontWeight: "800",
          }}
          focusStyle={{
            outlineColor: "var(--primary)",
          }}
          containerStyle={{
            display: "flex",
            justifyContent: "space-between",
          }}
        />

        <ErrorMessage show={verifyOtpErrors.otp}>
          {verifyOtpErrors.otp}
        </ErrorMessage>

        <Button
          style={{ width: "100%" }}
          title={"VERIFY OTP"}
          type={"submit"}
          isLoading={verifyRes.isLoading || loading}
          error={verifyOtpErrors.general}
        />
      </Form2>
      <OtpOptions style={{ paddingLeft: 0, paddingRight: 0 }}>
        {resendRes.isLoading ? (
          <span>Sending OTP...</span>
        ) : showResendOtp ? (
          <UnderLineButton
            onClick={() => {
              setSetResendOtpErrors({});
              resendRes.mutate();
              setShowResendOtp(false);
            }}
          >
            Resend otp
          </UnderLineButton>
        ) : (
          <div>
            <StyledAlignCenter>
              <span>Resend OTP in</span>
              <Timer onExpire={() => setShowResendOtp(true)} />
            </StyledAlignCenter>
            {resendRes.isError ? (
              <ErrorMessage show={resendRes.isError}>
                {resendOtpError.phone_number || resendOtpError.general}
              </ErrorMessage>
            ) : null}
          </div>
        )}

        <StyledLink onClick={onChangeNumber}>Change Number</StyledLink>
      </OtpOptions>
    </>
  );
};

export default OtpVerification;
