import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AutocompleteSearch from "components/ui/Autocomplete/Autocomplete";
import Button from "components/ui/Button/Button";
import DataRenderer from "components/utility/DataRenderer";
import { API_ENDPOINTS } from "utils/constants";

import { ErrorMessage } from "styles/common-styled-components";

const SelectBankForm = ({ state }) => {
  const navigate = useNavigate();

  const [selectedBank, setSelectedBank] = useState(null);
  const [error, setError] = useState("");

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  const navigateUrl = editMode
    ? "/registration/select-branch?edit_mode=true"
    : "/registration/select-branch";

  return (
    <div>
      <DataRenderer
        apiEndpoint={API_ENDPOINTS.getBanks}
        queryName={"banks"}
        renderData={(bankData) => {
          return (
            <div style={{}}>
              <AutocompleteSearch
                options={bankData.data}
                onChange={(option) => setSelectedBank(option)}
                value={selectedBank?.id}
                labelMapper="name"
                valueMapper="id"
              />
              <ErrorMessage show={error}>{error}</ErrorMessage>
              <Button
                title={"CONTINUE"}
                onClick={() => {
                  if (!selectedBank?.id) {
                    setError("Please select a Bank to continue");
                    return;
                  }
                  navigate(navigateUrl, {
                    state: {
                      bankId: selectedBank?.id,
                      bankName: selectedBank?.name,
                      nonReg: location.state?.nonReg,
                      fromLocation: location.state?.fromLocation,
                      editMode: true,
                    },
                  });
                }}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default SelectBankForm;
