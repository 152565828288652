import styled from "styled-components";

export const PinCont = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const Input = styled.input`
  border: 0;
  border-bottom: 2px solid var(--themeColor);
  padding: 4px;
  text-align: center;
  font-family: Verdana;
  font-size: "3.2rem";
  max-width: 4rem;

  &:focus {
    outline: none;
  }
`;
