import styled from "styled-components";
import { AlignCenter, Centering } from "styles/common-styles";
import pattern from "assets/banner/abstract-background-with-squares.jpg";

export const PageBg = styled.div`
  width: 100%;
  height: 100vh;
  ${Centering}
  background-image: url(${pattern});
  background-repeat: no-repeat;
  background-size: cover;
`;
export const PINCard = styled.div`
  ${AlignCenter}
  flex-direction: column;
  padding: 4rem;
  padding-bottom: 8rem;
  margin: 2rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 24rem;
  background-color: white;

  @media (min-width: 600px) {
    min-width: 50rem;
  }
`;
