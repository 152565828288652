import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p
          style={{
            margin: 0,
            fontWeight: "bold",
            color: "#555",
            fontSize: "14px",
          }}
        >
          {label}
        </p>
        {payload.map((item) => (
          <p
            key={item.dataKey}
            style={{
              margin: 4,
              color: item.stroke,
              fontSize: "13px",
              fontWeight: "500",
            }}
          >
            {item.name}: {item.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const DashBoardLineChart = ({
  data = [],
  graphColor1,
  graphColor2,
  returnColor,
  currentAmountKey,
  investedAmountKey,
  returnKey,
  useCustomTooltip = false,
  useStrokeDasharray = false,
  height = 300,
}) => {
  const lineThickness = 1;

  const isFlatLine = (key) => {
    if (!data.length) return true;
    const values = data.map((item) => item[key]);
    return values.every((val) => val === values[0]);
  };

  return (
    <ResponsiveContainer width="100%" height={Number(height)}>
      <LineChart data={data}>
        <XAxis dataKey="date" hide />
        <YAxis hide />
        {useCustomTooltip && <RechartsTooltip content={<CustomTooltip />} />}
        {currentAmountKey && (
          <Line
            type="monotone"
            dataKey={currentAmountKey}
            stroke={graphColor1}
            strokeWidth={lineThickness}
            isAnimationActive={true}
            animationDuration={1200}
            activeDot={
              isFlatLine(currentAmountKey)
                ? false
                : { r: 6, fill: "#fff", stroke: graphColor1, strokeWidth: 2 }
            }
            name="Current Amount"
            dot={false}
          />
        )}
        {investedAmountKey && (
          <Line
            type="monotone"
            dataKey={investedAmountKey}
            stroke={graphColor2}
            strokeWidth={lineThickness}
            isAnimationActive={true}
            animationDuration={1200}
            strokeDasharray={useStrokeDasharray ? "5 2" : undefined} // Conditional strokeDasharray
            activeDot={
              isFlatLine(investedAmountKey)
                ? false
                : { r: 6, fill: "#fff", stroke: graphColor2, strokeWidth: 2 }
            }
            name="Invested Amount"
            dot={false}
          />
        )}
        {returnKey && (
          <Line
            type="monotone"
            dataKey={returnKey}
            stroke={returnColor}
            strokeWidth={lineThickness}
            isAnimationActive={true}
            animationDuration={1200}
            activeDot={
              isFlatLine(returnKey)
                ? false
                : { r: 6, fill: "#fff", stroke: returnColor, strokeWidth: 2 }
            }
            name="Daily Return"
            dot={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashBoardLineChart;
