import React, { useState, useRef } from "react";
import { Input, PinCont } from "./PinInput.styles";

const PinInput = ({ onComplete }) => {
  const [pins, setPins] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, value) => {
    const newPins = [...pins];
    newPins[index] = value;

    setPins(newPins);

    // Focus the next input field if the value is not empty
    if (index < 3 && value !== "") {
      inputRefs[index + 1].current.focus();
    }

    // Check if all inputs are filled
    if (!newPins.includes("")) {
      onComplete(newPins.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && pins[index] === "") {
      // Move focus to the previous input field on Backspace
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <PinCont className="pin-input-container">
      {pins.map((pin, index) => (
        <Input
          key={index}
          type="password"
          value={pin}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          maxLength={1}
          ref={inputRefs[index]}
          className="pin-input"
          autoFocus={index === 0 && true}
        />
      ))}
    </PinCont>
  );
};

export default PinInput;
