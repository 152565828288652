import React from "react";
import { PulseLoader } from "react-spinners";

import { StyledButtonBase } from "./Button.styles";
import { ErrorMessage } from "styles/common-styled-components";

const Button = ({
  title,
  isLoading = false,
  error,
  contStyle,
  titleStyle = {},
  ...otherProps
}) => {
  return (
    <div style={{ width: "100%", ...contStyle }}>
      <StyledButtonBase {...otherProps} disabled={isLoading}>
        {isLoading ? (
          <PulseLoader size={"1.2rem"} color="white" />
        ) : (
          <p style={titleStyle}>{title}</p>
        )}
      </StyledButtonBase>
      {error && <ErrorMessage show={error}>{error}</ErrorMessage>}
    </div>
  );
};

export default Button;
