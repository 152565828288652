import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { capitalizeFundName } from "utils/functions";
import { credentialAxios } from "utils/api-calls";
import LineEllipsis from "components/common/LineEllipsis/LineEllipsis";
import Button from "components/ui/Button/Button";
import useDocumentTitle from "hooks/useDocumentTitle";

import {
  API_ENDPOINTS,
  COLLECTIONS,
  COLLECTION_OPTIONS,
  HARD_CODED_TOP_MUTUALFUNDS,
} from "utils/constants";

import { InvestmentsSummarySkeleton } from "./Explore.skeletons";

import {
  TrendingUp,
  TrendingDown,
  CallMade,
  Compare,
  ChevronRight,
} from "@mui/icons-material";

import {
  CompleteSetupCardContent,
  CompleteSetupCardCont,
  Container,
  ContAndFooter,
  TopFundCard,
  TopFundCont,
  TopFundImgCont,
  CollectionCont,
  CollectionCard,
  InvestmentsSummaryCard,
  CollectionContainer,
  CategoryCard,
  IconContainer,
  CardContent,
  Label,
  Description,
  StyledDiv,
} from "./Explore.styles";

import {
  CloseButton,
  StyledAlignCenter,
  StyledLink,
  StyledSpaceBetweenAlignCenter,
  SubHeading,
} from "styles/common-styled-components";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import warningImg from "assets/banner/Warning-bro.svg";
import clock from "assets/banner/Clock.svg";
import Footer from "components/global/Footer/Footer2";

const Explore = () => {
  useDocumentTitle("WiseBulls | Investing in Mutual Funds simplified");

  const navigate = useNavigate();
  const { currentUser, nextRegistrationScreen, userDetails } = useSelector(
    (state) => state.user
  );

  return (
    <ContAndFooter>
      <Container>
        <div style={{ flex: 2 }}>
          <StyledSpaceBetweenAlignCenter style={{ marginBottom: "1rem" }}>
            <h2
              style={{
                fontWeight: 500,
                fontSize: "1.8rem",
                // marginBottom: "1rem",
              }}
            >
              Collections
            </h2>
            <StyledLink
              to="/mutual-funds"
              style={{ color: "var(--themeColor)", fontWeight: "600" }}
            >
              <StyledAlignCenter>
                <span>Explore All</span>
                <CallMade />
              </StyledAlignCenter>
            </StyledLink>
          </StyledSpaceBetweenAlignCenter>

          <Collections2 />
        </div>
        <div style={{ flex: 1 }}>
          {!currentUser ? (
            <StyledDiv>
              <InvestmentsSummaryCard style={{}}>
                <div>Login to see your Investments Summary here</div>
              </InvestmentsSummaryCard>
            </StyledDiv>
          ) : userDetails.has_user_submitted_kyc ? (
            <PendingRegistrationCard />
          ) : nextRegistrationScreen ? (
            <CompleteSetupCard />
          ) : (
            <>
              <InvestmentSummary />

              <div
                onClick={() => navigate("/active-sips")}
                style={{ width: "100%" }}
              >
                <CategoryCard style={{ width: "100%" }}>
                  <StyledSpaceBetweenAlignCenter style={{ width: "100%" }}>
                    <p style={{ fontWeight: "500" }}>My Active SIPs</p>
                    <ChevronRight style={{ fontSize: "2.4rem" }} />
                  </StyledSpaceBetweenAlignCenter>
                </CategoryCard>
              </div>
            </>
          )}
          {/* <div>
            <h2
              style={{
                fontWeight: 500,
                fontSize: "1.6rem",
                marginBottom: "1rem",
              }}
            >
              Investment tools
            </h2>
            <div
              style={{
                padding: "2rem",
                border: "1px solid var(--light-grey)",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "rgba(0,0,0,0.1) 0px 4px 12px",
              }}
              onClick={() => navigate("/compare-funds")}
            >
              <Compare style={{ fontSize: "2.4rem" }} />
              <p>Compare Funds</p>
            </div>
          </div> */}
        </div>

        {/* <Button
        name="TESTING"
        onClick={() => {
          navigate("/upi-transaction-timer", {
            state: {
              monthlySipDate: 1,
              vpa_id: "6305251904@axl",
              bankName: `SOME BANK`,
              scheme_name: `A BIG SCHEME IN TH FALL`,
              id: 12,
              scheme_type: "sip",
              bse_code: null,
              amount: 200,
              payment_option: 1,
              bank: 0,
              dateValue: "12-11-2022",
              investmentType: "sip",
              order_number: "1212121212",
              order_id: "1212121212",
            },
          });
        }}
      /> */}
      </Container>
      <Footer />
    </ContAndFooter>
  );
};

export default Explore;

const Collections2 = () => {
  const navigate = useNavigate();
  return (
    <CollectionContainer>
      {COLLECTIONS.map((collection, index) => (
        <div
          onClick={() =>
            navigate(collection.path, {
              state: {
                INDEX: index,
              },
            })
          }
        >
          <CategoryCard key={index}>
            <IconContainer>{collection.icon}</IconContainer>
            <CardContent>
              <Label style={{ fontSize: "1.3rem" }}>{collection.label}</Label>
              <Description style={{ fontSize: "1.2rem" }}>
                {collection.description}
              </Description>
            </CardContent>
          </CategoryCard>
        </div>
      ))}
    </CollectionContainer>
  );
};

const TopFunds = () => {
  return (
    <TopFundCont>
      {HARD_CODED_TOP_MUTUALFUNDS.map((curr, i) => {
        return (
          <TopFundCard key={i} to={`/mutual-funds/${curr.id}`}>
            <div>
              <TopFundImgCont>
                <LazyLoadImage
                  style={{ width: "100%", height: "auto" }}
                  src={curr.logo}
                  alt={curr.title}
                />
              </TopFundImgCont>
              <LineEllipsis
                textStyle={{ fontSize: "1.4rem", textTransform: "uppercase" }}
                text={capitalizeFundName(curr.title)}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                fontSize: "1.4rem",
                fontWeight: "500",
              }}
            >
              <p>{curr.perc}%</p>
              <p style={{ color: "#b0b2ba", fontWeight: "500" }}>
                &nbsp;&nbsp;({curr.returns}Y)
              </p>
            </div>
          </TopFundCard>
        );
      })}
    </TopFundCont>
  );
};

const Collections = () => {
  return (
    <CollectionCont>
      {COLLECTION_OPTIONS.map((curr, i) => {
        return (
          <StyledLink to={curr.path} key={i}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CollectionCard>{curr.icon}</CollectionCard>
              <p
                style={{
                  fontSize: "1.4rem",
                  marginTop: "1rem",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {curr.name}
              </p>
            </div>
          </StyledLink>
        );
      })}
    </CollectionCont>
  );
};

export const CompleteSetupCard = ({
  style,
  closeModal = () => {},
  showClose = false,
}) => {
  const navigate = useNavigate();
  const { nextRegistrationScreen } = useSelector((state) => state.user);
  return (
    <StyledDiv>
      <CompleteSetupCardCont
        style={{ ...style, boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        {showClose ? <CloseButton onClick={closeModal} /> : null}
        <div style={{ height: "30vh" }}>
          <LazyLoadImage
            src={warningImg}
            alt="warning to complete registration"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>

        <CompleteSetupCardContent>
          <h2 style={{ marginBottom: "1rem" }}>Registration Incomplete</h2>
          <p style={{ marginBottom: "1rem" }}>
            Please complete your registration process to invest
          </p>
          <Button
            title="COMPLETE SETUP"
            style={{ width: "100%" }}
            onClick={() => {
              closeModal();
              navigate(nextRegistrationScreen);
            }}
          />
        </CompleteSetupCardContent>
      </CompleteSetupCardCont>
    </StyledDiv>
  );
};

export const PendingRegistrationCard = ({
  style,
  closeModal = () => {},
  showClose = false,
}) => {
  return (
    <StyledDiv>
      <CompleteSetupCardCont
        style={{ ...style, boxShadow: " 0 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        {showClose ? <CloseButton onClick={closeModal} /> : null}
        <div style={{ height: "30vh" }}>
          <LazyLoadImage
            src={clock}
            alt="warning to complete registration"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "var(--light-grey)",
              borderRadius: "8px  ",
            }}
          />
        </div>

        <CompleteSetupCardContent>
          <h2 style={{ marginBottom: "1rem" }}>Registration Pending</h2>
          <p style={{ marginBottom: "1rem" }}>
            Our team is currently verifying your details. Please try again
            shortly.
          </p>
        </CompleteSetupCardContent>
      </CompleteSetupCardCont>
    </StyledDiv>
  );
};

const InvestmentSummary = () => {
  const getInvestmentDetails = () => {
    return credentialAxios.get(API_ENDPOINTS.getInvestments);
  };

  const { data: investmentsData, isLoading } = useQuery(
    "investments",
    getInvestmentDetails
  );

  return isLoading ? (
    <InvestmentsSummarySkeleton />
  ) : (
    <>
      <StyledSpaceBetweenAlignCenter style={{ marginBottom: "1rem" }}>
        <h2
          style={{
            fontWeight: 500,
            fontSize: "1.8rem",
            // marginBottom: "1rem",
          }}
        >
          Investment Summary
        </h2>
        <StyledLink
          to="/investments"
          style={{ color: "var(--themeColor)", fontWeight: "600" }}
        >
          <StyledAlignCenter>
            <span>Investments</span>
            <CallMade />
          </StyledAlignCenter>
        </StyledLink>
      </StyledSpaceBetweenAlignCenter>
      <InvestmentsSummaryCard
        style={{
          backgroundColor: "transparent",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* <StyledAlignCenter style={{}}>
          <StyledAlignCenter>
            <SubHeading style={{ marginBottom: 0 }}>
              Investments Summary
            </SubHeading>
            <span style={{}}>
              ({investmentsData.data.summary.total_returns_percent}%)
            </span>
          </StyledAlignCenter>

          {investmentsData.data.summary.gaining ? (
            <TrendingUp
              style={{ color: "var(--themeColor)", fontSize: "2.4rem" }}
            />
          ) : (
            <TrendingDown
              style={{ color: "var(--errorTextColor)", fontSize: "2.4rem" }}
            />
          )}
        </StyledAlignCenter>
        <p style={{ fontSize: "1.2rem" }}>
          (XIRR: {investmentsData.data.summary?.overall_xirr})
        </p> */}
        <div style={{ textAlign: "left", width: "100%" }}>
          <p
            style={{
              fontSize: "1.3rem",
              fontWeight: "500",
              marginBottom: "4px",
            }}
          >
            Current Value
          </p>
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "800",
              color: investmentsData.data.summary.gaining
                ? "var(--themeColor)"
                : "var(--errorTextColor)",
            }}
          >
            ₹{Number(investmentsData.data.summary.current_value).toFixed(2)}
          </p>
        </div>
        <StyledSpaceBetweenAlignCenter
          style={{
            width: "100%",
            textAlign: "left",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontSize: "1.4rem",
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              Invested Amount
            </p>
            <p style={{ fontSize: "1.6rem", fontWeight: "800" }}>
              ₹
              {Number(investmentsData.data.summary.total_investment).toFixed(2)}
            </p>
          </div>

          {/* Vertical Line */}
          <div
            style={{
              width: "1px",
              backgroundColor: "#ccc",
              height: "4rem",
            }}
          />

          <div style={{ flex: 1, textAlign: "right" }}>
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: "500",
                marginBottom: "4px",
              }}
            >
              Returns
            </p>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "800",
                color: investmentsData.data.summary.gaining
                  ? "var(--themeColor)"
                  : "var(--errorTextColor)",
              }}
            >
              ₹
              {(
                Number(investmentsData.data.summary.current_value) -
                Number(investmentsData.data.summary.total_investment)
              ).toFixed(2)}
            </p>
          </div>
        </StyledSpaceBetweenAlignCenter>

        {/* <StyledLink to={"/investments"} style={{ width: "100%" }}>
          <Button title="See All Investments" />
        </StyledLink> */}
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
          }}
        >
          <Link
            to="/portfolio"
            style={{
              textDecoration: "none",
              color: "var(--themeColor)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <EqualizerIcon style={{ marginRight: "4px" }} />
            <p>Portfolio Analysis</p>
          </Link>
        </div>
      </InvestmentsSummaryCard>
    </>
  );
};
