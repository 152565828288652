import React, { useState } from "react";
import Table from "components/ui/Table/Table";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";

import RadioComp from "components/ui/RadioComp/RadioComp";
import SelectBankForm from "components/forms/SelectBankForm";
import FetchBankFromIfsc from "components/forms/FetchBankFromIfsc";
import { StyledAlignCenter } from "styles/common-styled-components";
import AccordionComp from "components/ui/AccordionComp/AccordionComp";
import { Form2 } from "styles/common-styled-components";

const SelectBank = () => {
  const [selectFormat, setSelectFormat] = useState("name");

  const { userDetails } = useSelector((state) => state.user);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  return (
    <>
      <RegistrationCapsule
        heading={`${editMode ? "Edit" : "Add"} your Bank account`}
        para={"Select your Bank to proceed with the adding Bank."}
        fromLocation={editMode ? "/registration/confirm-details" : ""}
      >
        <Form2>
          {editMode ? (
            <AccordionComp
              title={"Your current bank detail"}
              content={
                <Table
                  style={{ marginBottom: "2rem" }}
                  data={{
                    "Bank name": userDetails.bankdetail[0].bank_name,
                    "Branch IFSC": userDetails.bankdetail[0].branch_ifsc,
                    "Branch name": userDetails.bankdetail[0].branch_name,
                    "Account number": userDetails.bankdetail[0].account_number,
                    "Account type":
                      userDetails.bankdetail[0].account_type_bse === "SB"
                        ? "Savings"
                        : "Current",
                  }}
                />
              }
            />
          ) : null}
          <div>
            <RadioComp
              selected={selectFormat === "name"}
              label={"Select your Bank"}
              onChange={() => setSelectFormat("name")}
            >
              <SelectBankForm />
            </RadioComp>
            <StyledAlignCenter
              style={{
                gap: "2rem",
                margin: "2rem 0",
                marginLeft: "calc(14px +  4rem)",
              }}
            >
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#ddd",
                  width: "100%",
                }}
              />
              <div>or</div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#ddd",
                  width: "100%",
                }}
              />
            </StyledAlignCenter>
            <RadioComp
              selected={selectFormat === "ifsc"}
              label={"Enter your Bank IFSC Code"}
              onChange={() => setSelectFormat("ifsc")}
            >
              <FetchBankFromIfsc />
            </RadioComp>
          </div>
        </Form2>
      </RegistrationCapsule>
    </>
  );
};

export default SelectBank;
