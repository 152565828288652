import React, { useState } from "react";
import styled from "styled-components";

const Help = () => {
  const [selectedCategory, setSelectedCategory] = useState("kyc");
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const questions = {
    kyc: [
      {
        question: "What is KYC, and why is it required?",
        answer: "KYC stands for 'Know Your Customer'...",
      },
      {
        question: "How do I complete my KYC process?",
        answer: "You can complete the KYC process...",
      },
      {
        question: "Is KYC mandatory for all transactions?",
        answer: "Yes, KYC is mandatory...",
      },
    ],
    transactions: [
      {
        question: "How do I purchase a mutual fund?",
        answer: "To purchase a mutual fund, log in...",
      },
      {
        question: "What is the minimum investment amount?",
        answer: "The minimum investment...",
      },
      {
        question: "Can I purchase mutual funds through SIP?",
        answer: "Yes, you can invest in mutual funds...",
      },
    ],
    redemption: [
      {
        question: "How do I redeem my mutual funds?",
        answer: "To redeem your mutual funds...",
      },
      {
        question: "How long does it take to receive redemption proceeds?",
        answer: "Redemption proceeds...",
      },
      {
        question: "Are there any charges for redeeming mutual funds?",
        answer: "Some mutual funds may have...",
      },
    ],
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSearchQuery("");
    setShowDropdown(false);
    setExpanded(null);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setShowDropdown(query.length > 0);
  };

  const allQuestions = Object.entries(questions).flatMap(([category, items]) =>
    items.map((item, index) => ({
      ...item,
      category,
      id: `${category}-${index}`,
    }))
  );

  const globalFilteredQuestions = allQuestions.filter((item) =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDropdownSelect = (selectedItem) => {
    setSelectedCategory(selectedItem.category);
    setSearchQuery(selectedItem.question);
    setShowDropdown(false);
    setExpanded(selectedItem.id);
  };

  const filteredQuestions = questions[selectedCategory];

  return (
    <PageContainer>
      <HeaderSection>
        <ContentContainer>
          <Title>Frequently Asked Questions</Title>
          <Description>
            Browse our FAQ library and find answers to any question you may
            have. Use the search bar below to narrow down results.
          </Description>
          <SearchContainer>
            <SearchInput
              placeholder="Search by keywords..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {showDropdown && (
              <Dropdown>
                {globalFilteredQuestions.length > 0 ? (
                  globalFilteredQuestions.map((item) => (
                    <DropdownItem
                      key={item.id}
                      onClick={() => handleDropdownSelect(item)}
                    >
                      {item.question}
                    </DropdownItem>
                  ))
                ) : (
                  <DropdownItem disabled>No results found</DropdownItem>
                )}
              </Dropdown>
            )}
          </SearchContainer>
        </ContentContainer>
      </HeaderSection>

      <ContentContainer>
        <GridContainer>
          <LeftColumn>
            <CategoryTitle>Categories</CategoryTitle>
            <CategoryList>
              {Object.keys(questions).map((category) => (
                <CategoryItem
                  key={category}
                  selected={selectedCategory === category}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category.toUpperCase()}
                </CategoryItem>
              ))}
            </CategoryList>
          </LeftColumn>

          <RightColumn>
            {filteredQuestions.map((item, index) => (
              <AccordionContainer
                key={index}
                expanded={expanded === `${selectedCategory}-${index}`}
                onClick={() =>
                  setExpanded(
                    expanded === `${selectedCategory}-${index}`
                      ? null
                      : `${selectedCategory}-${index}`
                  )
                }
              >
                <AccordionTitle>{item.question}</AccordionTitle>
                {expanded === `${selectedCategory}-${index}` && (
                  <AccordionContent>{item.answer}</AccordionContent>
                )}
              </AccordionContainer>
            ))}
          </RightColumn>
        </GridContainer>
      </ContentContainer>

      <ContactSection>
        <ContentContainer>
          <ContactTitle>Reach Out to Us</ContactTitle>
          <ContactDescription>
            We're here to help! If you have any further questions, please feel
            free to contact us through any of the options below.
          </ContactDescription>

          <ContactGrid>
            <ContactItem>
              <ContactSubtitle>📞 Phone</ContactSubtitle>
              <ContactText>+91 9980018625</ContactText>
            </ContactItem>
            <ContactItem>
              <ContactSubtitle>📧 Email</ContactSubtitle>
              <ContactText>info@wisebulls.com</ContactText>
            </ContactItem>
            <ContactItem>
              <ContactSubtitle>🕒 Support Hours</ContactSubtitle>
              <ContactText>Mon-Fri: 9 AM - 6 PM</ContactText>
            </ContactItem>
          </ContactGrid>
        </ContentContainer>
      </ContactSection>
    </PageContainer>
  );
};

export default Help;

const PageContainer = styled.div`
  @media (max-width: 980px) {
    margin-top: 6rem;
  }
`;

const HeaderSection = styled.div`
  background-color: var(--themeColorShade1);
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 1.4rem;
  margin-top: 10px;
  line-height: 1.6;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1.4rem;
  border-radius: 5px;
  border: 1px solid #20b2aa;
  outline: none;
  box-sizing: border-box;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 5px;
`;

const DropdownItem = styled.div`
  padding: 10px;
  font-size: 1.4rem;
  color: #333;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;

const LeftColumn = styled.div``;

const CategoryTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  color: #20b2aa;
`;

const CategoryList = styled.ul`
  margin-top: 20px;
  list-style-type: none;
  padding: 0;
`;

const CategoryItem = styled.li`
  padding: 10px;
  font-size: 1.4rem;
  color: ${({ selected }) => (selected ? "#333" : "#333")};
  background-color: ${({ selected }) =>
    selected ? "var(--themeColorShade1)" : "transparent"};
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: var(--themeColorShade1);
    color: #333;
  }
`;

const RightColumn = styled.div``;

const AccordionContainer = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  padding: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  background-color: #f1f1f1;
  border-radius: 5px;
`;

const AccordionContent = styled.div`
  padding: 20px;
  font-size: 1.3rem;
  color: #666;
  line-height: 1.6;
  background-color: #f9f9f9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ContactSection = styled.div`
  margin-top: 60px;
  background-color: var(--themeColorShade1);
  border-radius: 8px;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ContactTitle = styled.h2`
  font-size: 2rem;
  font-weight: 600;
`;

const ContactDescription = styled.p`
  font-size: 1.4rem;
  margin-top: 10px;
  line-height: 1.6;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const ContactItem = styled.div``;

const ContactSubtitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;

const ContactText = styled.p`
  font-size: 1.4rem;
`;
