import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({
  numberOfMinutes = 1,
  onExpire = () => console.warn("onExpire called"),
}) => {
  const NUMBER_OF_MINUTES = numberOfMinutes;
  const NUMBER_OF_SECONDS = NUMBER_OF_MINUTES * 60;
  const expiryTimestamp = Date.now() + NUMBER_OF_SECONDS * 1000;

  const { seconds, minutes } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  return (
    <div>
      {`${minutes}`.padStart(2, 0)}:{`${seconds}`.padStart(2, 0)}
    </div>
  );
};

export default Timer;
