import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { credentialAxios, publicAxios } from "utils/api-calls";
import {
  API_ENDPOINTS,
  GENERIC_ERROR_RES_MSG,
  REGULAR_EXPRESSIONS,
} from "utils/constants";
import {
  getNextRegistrationScreen,
  mapServerErrorsToLocal,
} from "utils/functions";
import {
  addKycInfo,
  addNextRegistrationScreen,
  addUserDetails,
} from "redux/user/user-slice";
import LoginOTPForm from "./LoginOTPForm";
import { Form2 } from "styles/common-styled-components";
import { addUser } from "redux/user/user-slice";
import { AlertContext } from "App";

const LoginForm = ({ closeModal = () => {}, fromLocation = "/" }) => {
  const formikRef = useRef(null);
  const { showMessage } = useContext(AlertContext);
  const initialErrorState = { phone_number: "", general: "" };
  const validationSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(REGULAR_EXPRESSIONS.phone_number, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showOTPModule, setShowOTPModule] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // API CALLS

  const handleLogin = async (payload) => {
    try {
      setIsLoggingIn(true);

      await publicAxios.post(API_ENDPOINTS.authSendOtp, {
        phone_number: payload.phone_number,
      });

      showMessage("OTP sent Successfully");

      setErrors(initialErrorState);

      setShowOTPModule(true);

      setIsLoggingIn(false);
    } catch (error) {
      console.log(error);
      setIsLoggingIn(false);
      const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
        "phone_number",
      ]);
      setErrors(newErrors);
    } finally {
      console.log("Finally block");
    }
  };

  const fetchUserInfoAndStore = async (access, refresh) => {
    try {
      setShowLoading(true);

      dispatch(
        addUser({
          access,
          refresh,
        })
      );

      console.log(`------ Login success: Setting auth-checked to True ------`);
      sessionStorage.setItem("auth-checked", true);

      // Fetch user info
      const userInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getUserInfo
      );

      console.log(`------ Setting user-info for auth ------`);

      dispatch(addUserDetails(userInfoResponse.data));

      // Fetch KYC info
      const kycInfoResponse = await credentialAxios.get(
        API_ENDPOINTS.getKycData
      );

      dispatch(addKycInfo(kycInfoResponse.data));

      const nextRegistrationScreen = getNextRegistrationScreen(
        userInfoResponse.data,
        kycInfoResponse.data
      );

      dispatch(addNextRegistrationScreen(nextRegistrationScreen));

      if (nextRegistrationScreen) {
        navigate(nextRegistrationScreen, { replace: true });
        window.location.reload();
      } else {
        navigate(fromLocation, { replace: true });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      setErrors((prev) => {
        return { ...prev, general: GENERIC_ERROR_RES_MSG };
      });
    }
  };

  ///// API CALL END
  if (showOTPModule) {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={{
          phone_number: formikRef?.current?.values?.phone_number || "",
        }}
      >
        {({ values }) => (
          <LoginOTPForm
            onOtpSuccess={(access_token, refresh_token) => {
              fetchUserInfoAndStore(access_token, refresh_token);
            }}
            phone_number={values.phone_number}
            onChangeNumber={() => {
              setShowOTPModule(false);
            }}
            loading={showLoading}
          />
        )}
      </Formik>
    );
  }
  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={{
          phone_number: "",
        }}
        onSubmit={handleLogin}
      >
        {({
          values,
          handleSubmit,
          errors: formikErrors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form2
              style={{ padding: 0, margin: "3rem 0" }}
              onSubmit={handleSubmit}
            >
              <InputField
                label={"Phone Number"}
                labelInputDirection="vertical"
                autoFocus="true"
                placeholder="1234 5678 90"
                type="tel"
                maxLength="10"
                value={values.phone_number}
                onChange={(e) => {
                  const numericValue = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 10); // Limit to numeric and 10 characters
                  setFieldValue("phone_number", numericValue);
                }}
                appendLeft={<>+91</>}
                error={
                  touched.phone_number &&
                  (formikErrors.phone_number || errors.phone_number)
                }
                noSpin
              />

              <Button
                type="submit"
                title="SEND OTP"
                isLoading={isLoggingIn}
                loadingText=""
                error={errors.general}
              />
            </Form2>
          );
        }}
      </Formik>
    </>
  );
};

export default LoginForm;
