import { AlertContext } from "App";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "components/ui/InputField/InputField.jsx";
import * as yup from "yup";
import { API_ENDPOINTS, REGULAR_EXPRESSIONS } from "utils/constants";
import { credentialAxios } from "utils/api-calls";
import { mapServerErrorsToLocal } from "utils/functions";
import Button from "components/ui/Button/Button";
import { Formik } from "formik";

export const UPIForm = ({ selectedBankForPayment, vpa }) => {
  const initialErrorState = {
    vpa_id: "",
    general: "",
  };
  useEffect(() => {
    console.log(vpa);
  }, []);
  const validationSchema = yup.object().shape({
    vpa_id: yup
      .string()
      .matches(REGULAR_EXPRESSIONS.vpa_id, "UPI ID is not valid")
      .required("UPI ID is required"),
  });

  const formikRef = useRef(null);

  const { state: accumulatedPayload } = useLocation();
  const { showMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const getPayload = (vpa_id, is_order_continued = false) => {
    let tempData = {
      payment_option: 1,
      bank: selectedBankForPayment,
      otp_required: true,
      otp: "",
      amount: +accumulatedPayload.amount,
      vpa_id,
    };
    if (is_order_continued) {
      tempData["order_number"] = accumulatedPayload.order_number;
    } else {
      tempData["bse_code"] = accumulatedPayload.bse_code;
    }
    if (accumulatedPayload?.folio_number) {
      tempData["folio_number"] = accumulatedPayload.folio_number;
    }
    if (accumulatedPayload.investmentType === "sip")
      tempData["start_date"] = parseInt(accumulatedPayload.monthlySipDate);

    return tempData;
  };

  const confirmOrder = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(formikRef.current.values.vpa_id, true)
      : getPayload(formikRef.current.values.vpa_id, false);

    navigate("/confirm-order", { state: payload, replace: true });
  };

  const initiateTransaction = ({ vpa_id }) => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(vpa_id, true)
      : getPayload(vpa_id, false);

    if (accumulatedPayload.comingFromOrders) {
      return credentialAxios.post(API_ENDPOINTS.payFromOrders, payload);
    } else {
      return credentialAxios.post(
        accumulatedPayload.investmentType === "sip"
          ? API_ENDPOINTS.createSipTransaction
          : API_ENDPOINTS.createOneTimeTransaction,
        payload
      );
    }
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);

    if (accumulatedPayload.comingFromOrders) {
      if (res.data.request_sent) {
        navigate("/upi-transaction-timer", {
          state: {
            order_id: accumulatedPayload.order_number,
            amount: accumulatedPayload.amount,
          },
        });
      }
    } else {
      if (res.data.otp_sent) {
        showMessage(`OTP sent Successfully`);
        confirmOrder();
      }
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "vpa_id",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(initiateTransaction, {
    onError,
    onSuccess,
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        vpa_id: vpa,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutate(values);
      }}
    >
      {({ values, errors, handleSubmit, handleChange, touched, isValid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <InputField
              onChange={handleChange("vpa_id")}
              value={values.vpa_id}
              error={touched.vpa_id && (errors.vpa_id || errorState.vpa_id)}
              placeholder="9000000000@bank"
            />

            <Button
              type={"submit"}
              style={{ width: "100%", marginTop: "2rem" }}
              title={
                accumulatedPayload.comingFromOrders ? `CONTINUE` : `SEND OTP`
              }
              isLoading={isLoading}
              error={errorState.general}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export const NetBankingForm = ({ selectedBankForPayment }) => {
  const initialErrorState = {
    general: "",
  };
  const { state: accumulatedPayload } = useLocation();
  const { showMessage } = useContext(AlertContext);

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const getPayload = (is_order_continued = false) => {
    let tempData = {
      payment_option: 2,
      bank: selectedBankForPayment,
      otp_required: true,
      otp: "",
      amount: +accumulatedPayload.amount,
    };
    if (is_order_continued) {
      tempData["order_number"] = accumulatedPayload.order_number;
    } else {
      tempData["bse_code"] = accumulatedPayload.bse_code;
    }
    if (accumulatedPayload?.folio_number) {
      tempData["folio_number"] = accumulatedPayload.folio_number;
    }
    if (accumulatedPayload.investmentType === "sip")
      tempData["start_date"] = parseInt(accumulatedPayload.monthlySipDate);

    return tempData;
  };

  const confirmOrder = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(true)
      : getPayload(false);

    navigate("/confirm-order", { state: payload });
  };

  const initiateTransaction = () => {
    const payload = accumulatedPayload.comingFromOrders
      ? getPayload(true)
      : getPayload(false);

    if (accumulatedPayload.comingFromOrders) {
      return credentialAxios.post(API_ENDPOINTS.payFromOrders, payload);
    } else {
      return credentialAxios.post(
        accumulatedPayload.investmentType === "sip"
          ? API_ENDPOINTS.createSipTransaction
          : API_ENDPOINTS.createOneTimeTransaction,
        payload
      );
    }
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);

    if (accumulatedPayload.comingFromOrders) {
      const html = res.data.bank_html;

      navigate("/payment-web", {
        state: {
          html,
        },
      });
    } else {
      if (res.data.otp_sent) {
        showMessage(`OTP sent Successfully`);
        confirmOrder();
      }
    }
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(initiateTransaction, {
    onError,
    onSuccess,
  });

  return (
    <>
      <Button
        onClick={() => {
          mutate();
        }}
        error={errorState.general}
        style={{ width: "100%", marginTop: "2rem" }}
        isLoading={isLoading}
        title={accumulatedPayload.comingFromOrders ? `CONTINUE` : `SEND OTP`}
      />
    </>
  );
};
