import DownloadReports from "pages/Investments/DownloadReports";
import React from "react";
import {
  HeadingPrimary,
  HeadingPrimaryBold,
  MobileView,
  Paragraph,
} from "styles/common-styled-components";

const Reports = () => {
  return (
    <div>
      <MobileView>
        <div style={{ height: "8rem" }} />
      </MobileView>
      <div>
        <HeadingPrimary>
          <HeadingPrimaryBold>Reports</HeadingPrimaryBold>
        </HeadingPrimary>
        <Paragraph style={{ marginBottom: "2rem" }}>
          Download your mutual fund reports
        </Paragraph>
        <DownloadReports />
      </div>
    </div>
  );
};

export default Reports;
