import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const TableContainer = styled.div`
  margin: 0 auto;
  position: relative;
  font-size: 1.4rem;
`;

const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
`;

const Thead = styled.thead`
  border-bottom: 1px solid #ddd;
  tr {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const Th = styled.th`
  padding: 20px 20px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #adb5bd;
  border-bottom: 1px solid #ddd;
  text-align: left;

  &:nth-child(1) {
    width: 40%; /* Reduced width for Scheme Name */
  }
  &:nth-child(2) {
    width: 30%;
    text-align: right;
  }
  &:nth-child(3) {
    width: 30%;
    text-align: right;
  }
`;

const FundSizeTh = styled(Th)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const Tr = styled.tr``;

const Td = styled.td`
  padding: 16px 20px;
  &:nth-child(1) {
    width: 40%; /* Reduced width for Scheme Name */
  }
  &:nth-child(2) {
    width: 30%;
    text-align: right;
  }
  &:nth-child(3) {
    width: 30%;
    text-align: right;
  }
`;

const FundSizeTD = styled(Td)`
  @media (max-width: 600px) {
    display: none;
  }
`;

const Button = styled.button`
  display: block;
  margin: 0 auto;
  padding: 5px 10px;
  font-size: 16px;
  color: #fff;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateY(50%);
  &:hover {
    background-color: #ddd;
  }
`;

const fadeIn = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    max-height: 1000px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

const Tbody = styled.tbody`
  animation: ${({ showAll }) => (showAll ? fadeIn : fadeOut)} 0.3s ease-in-out;
  overflow: hidden;
`;

const CustomDropdown = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 12px;
  height: 100px;
`;

const DropdownButton = styled.div`
  background-color: #f8f9fa;
  color: #495057;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
`;

const DropdownContent = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  height: 200px;
  overflow-y: auto;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

const PeerComparision = ({ funds }) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedReturnType, setSelectedReturnType] =
    useState("one_year_return");
  const [showDropdown, setShowDropdown] = useState(false);

  const displayedFunds = showAll ? funds : funds.slice(0, 5);

  const handleReturnTypeChange = (returnType) => {
    setSelectedReturnType(returnType);
    setShowDropdown(false);
  };

  const returnOptions = [
    { label: "1W", value: "one_week_return" },
    { label: "1M", value: "one_month_return" },
    { label: "3M", value: "three_month_return" },
    { label: "6M", value: "six_month_return" },
    { label: "1Y", value: "one_year_return" },
    { label: "2Y", value: "two_year_return" },
    { label: "3Y", value: "three_year_return" },
    { label: "5Y", value: "five_year_return" },
    { label: "10Y", value: "ten_year_return" },
    { label: "Inception", value: "inception_year_return" },
    { label: "YTD", value: "ytd_return" },
  ];

  return (
    <TableContainer>
      <Table>
        <Thead>
          <tr>
            <Th>Scheme Name</Th>
            <Th>
              Returns
              <CustomDropdown>
                <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
                  {
                    returnOptions.find(
                      (option) => option.value === selectedReturnType
                    )?.label
                  }
                  {showDropdown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </DropdownButton>
                <DropdownContent show={showDropdown}>
                  {returnOptions.map((option, index) => (
                    <DropdownItem
                      key={index}
                      onClick={() => handleReturnTypeChange(option.value)}
                    >
                      {option.label}
                    </DropdownItem>
                  ))}
                </DropdownContent>
              </CustomDropdown>
            </Th>
            <FundSizeTh>Fund Size</FundSizeTh>
          </tr>
        </Thead>
        <Tbody showAll={showAll}>
          {displayedFunds.map((fund, index) => (
            <Tr key={index}>
              <Td>{fund.scheme_name}</Td>
              <Td>
                {fund[selectedReturnType] !== undefined
                  ? `${fund[selectedReturnType]}%`
                  : "-"}
              </Td>
              <FundSizeTD>{fund.scheme_assets.toLocaleString()}</FundSizeTD>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {funds.length > 5 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <KeyboardArrowUp
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={{
                color: "#000",
                fontSize: "2rem",
              }}
            />
          )}
        </Button>
      )}
    </TableContainer>
  );
};

export default PeerComparision;
