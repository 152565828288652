import React, { useState } from "react";
import Button from "components/ui/Button/Button";
import Select from "components/ui/Select/Select";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { StyledAlignCenter } from "styles/common-styled-components";
import { Download } from "@mui/icons-material";
import {
  BankAccountCard,
  BankAccountsCont,
  BankDetailsCont,
  PageCont,
} from "pages/BankDetails/BankDetails.styles";

const DownloadReports = () => {
  const [loadingState, setLoadingState] = useState({
    transactions: false,
    capitalGains: false,
    elssStatement: false,
  });
  const [error, setError] = useState(null);
  const [selectedReport, setSelectedReport] = useState("capitalGains");
  const [selectedYear, setSelectedYear] = useState("");

  const reportOptions = [
    {
      id: "capitalGains",
      label: "Capital Gains",
      endpoint: API_ENDPOINTS.downlaodCapitalGains,
    },
    {
      id: "elssStatement",
      label: "ELSS Statement",
      endpoint: API_ENDPOINTS.downloadElssDStatement,
    },
    {
      id: "transactions",
      label: "Transactions",
      endpoint: API_ENDPOINTS.downloadReports,
    },
  ];

  const handleDownload = async (endpoint, filename, params = {}) => {
    setError(null);
    setLoadingState((prevState) => ({ ...prevState, [filename]: true }));
    try {
      const res = await credentialAxios.get(endpoint, {
        responseType: "blob",
        params,
      });

      if (res.status !== 200) {
        throw new Error("Failed to download the report.");
      }

      const today = new Date().toISOString().split("T")[0];
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename}_${today}.csv`;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      setError(error.message || "An error occurred during download.");
    } finally {
      setLoadingState((prevState) => ({ ...prevState, [filename]: false }));
    }
  };

  const handleReportDownload = () => {
    const selectedOption = reportOptions.find(
      (option) => option.id === selectedReport
    );

    if (
      selectedReport === "capitalGains" ||
      selectedReport === "elssStatement"
    ) {
      if (!selectedYear) {
        setError("Please select a financial year.");
        return;
      }
      handleDownload(selectedOption.endpoint, selectedOption.id, {
        financial_year: selectedYear,
      });
    } else {
      handleDownload(selectedOption.endpoint, selectedOption.id);
    }
  };

  return (
    <PageCont>
      <BankAccountsCont style={{ overflow: "hidden" }}>
        {reportOptions.map((option) => (
          <BankAccountCard
            key={option.id}
            active={selectedReport === option.id}
            onClick={() => setSelectedReport(option.id)}
          >
            <span style={{ fontSize: "1.6rem", fontWeight: "500" }}>
              {option.label}
            </span>
          </BankAccountCard>
        ))}
      </BankAccountsCont>
      <BankDetailsCont>
        <div
          style={{
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {(selectedReport === "capitalGains" ||
            selectedReport === "elssStatement") && (
            <Select
              options={[
                { label: "Apr 2024 - Mar 2025", value: "2024-2025" },
                { label: "Apr 2023 - Mar 2024", value: "2023-2024" },
                { label: "Apr 2022 - Mar 2023", value: "2022-2023" },
                { label: "Apr 2021 - Mar 2022", value: "2021-2022" },
                { label: "Apr 2020 - Mar 2021", value: "2020-2021" },
              ]}
              label="Choose financial year"
              value={selectedYear}
              onChange={(value) => setSelectedYear(value)}
            />
          )}
          <Button
            title={
              <StyledAlignCenter>
                <Download />
                <span>
                  {loadingState[selectedReport]
                    ? "Downloading..."
                    : `Download ${
                        reportOptions.find(
                          (option) => option.id === selectedReport
                        )?.label
                      }`}
                </span>
              </StyledAlignCenter>
            }
            onClick={handleReportDownload}
            isLoading={loadingState[selectedReport]}
          />
        </div>
        {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}
      </BankDetailsCont>
    </PageCont>
  );
};

export default DownloadReports;
