import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import Timer from "components/ui/Timer/Timer";
import Button from "components/ui/Button/Button";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { mapServerErrorsToLocal } from "utils/functions";

import {
  ErrorMessage,
  Form,
  Form2,
  StyledAlignCenter,
  StyledLink,
  UnderLineButton,
} from "styles/common-styled-components";
import styled from "styled-components";

const OtpOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;

  @media (max-width: 600px) {
    padding: 1rem;
  }
`;
const OtpVerification = () => {
  const initialErrorState = { otp: "", general: "" };
  const resendInitialErrorState = { email: "", general: "" };

  const NUM_INPUTS = 6;

  const navigate = useNavigate();
  const { state } = useLocation();

  const fromLocation = state?.fromLocation;
  const navigateTo = fromLocation || "/registration/verify-pan";

  const [verifyOtpErrors, setVerifyOtpErrors] = useState(initialErrorState);
  const [resendOtpError, setSetResendOtpErrors] = useState(
    resendInitialErrorState
  );
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [otpValue, setOtpValue] = useState(null);

  const verifyOtp = (otp) => {
    return credentialAxios.post(API_ENDPOINTS.authVerifyEmailOtp, {
      otp,
    });
  };

  const onVerifyOtpSuccess = (res) => {
    setVerifyOtpErrors(initialErrorState);
    if (res.data?.otp_verified) {
      navigate(navigateTo);
    }
  };

  const onVerifyOtpError = (error) => {
    const newErrors = mapServerErrorsToLocal(
      error,
      initialErrorState,
      ["otp"],
      { error: "otp" }
    );
    setVerifyOtpErrors(newErrors);
  };

  const verifyRes = useMutation(verifyOtp, {
    onError: onVerifyOtpError,
    onSuccess: onVerifyOtpSuccess,
  });

  const resendOtp = () => {
    return credentialAxios.post(API_ENDPOINTS.authSendEmailOtp, {
      email: state.email,
      password: state.password,
    });
  };

  const onResendOtpSuccess = (res) => {};

  const onResendOtpError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, resendInitialErrorState, [
      "email",
    ]);
    setSetResendOtpErrors(newErrors);
  };

  const resendRes = useMutation(resendOtp, {
    onSuccess: onResendOtpSuccess,
    onError: onResendOtpError,
  });

  const handleValidateNoOtp = (e) => {
    e.preventDefault();
    if (!otpValue) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "OTP is required",
        };
      });
      return;
    } else if (otpValue.length < 6) {
      setVerifyOtpErrors((prev) => {
        return {
          ...prev,
          otp: "Enter all digits",
        };
      });
      return;
    }
    verifyRes.mutate(otpValue);
  };

  useEffect(() => {
    if (!state?.email) {
      // Handle the absence of data, e.g., redirect or show an error message
      navigate("/registration/add-email");
    }
    // Continue with your component logic using dataFromPreviousRoute
  }, [state?.email, navigate]);

  return (
    <>
      <RegistrationCapsule
        heading={"OTP Verification"}
        para={
          " OTP has been sent to your email. Please enter it below. OTP is valid for 3 minutes"
        }
        fromLocation={fromLocation}
      >
        <Form2 onSubmit={handleValidateNoOtp}>
          <OtpInput
            shouldAutoFocus={true}
            value={otpValue}
            onChange={(otp) => {
              setVerifyOtpErrors((prev) => {
                return { ...prev, otp: "" };
              });
              setOtpValue(otp);
              if (otp.length === NUM_INPUTS) {
                verifyRes.mutate(otp);
              }
            }}
            numInputs={NUM_INPUTS}
            inputStyle={{
              width: "5rem",
              height: "5rem",
              borderRadius: "6px",
              border: `1px solid ${
                verifyOtpErrors.otp ? "var(--danger)" : "#ccc"
              }`,
              fontSize: "2.4rem",
              fontWeight: "800",
            }}
            focusStyle={{
              outlineColor: "var(--primary)",
            }}
            containerStyle={{
              display: "flex",
              justifyContent: "space-between",
            }}
          />

          <ErrorMessage show={verifyOtpErrors.otp}>
            {verifyOtpErrors.otp}
          </ErrorMessage>

          <Button
            style={{ width: "100%" }}
            title={"VERIFY OTP"}
            type={"submit"}
            isLoading={verifyRes.isLoading}
            error={verifyOtpErrors.general}
          />
        </Form2>
        <OtpOptions style={{}}>
          {resendRes.isLoading ? (
            <span>Sending OTP...</span>
          ) : showResendOtp ? (
            <UnderLineButton
              onClick={() => {
                setSetResendOtpErrors({});
                resendRes.mutate();
                setShowResendOtp(false);
              }}
            >
              Resend otp
            </UnderLineButton>
          ) : (
            <div>
              <StyledAlignCenter>
                <span>Resend OTP in</span>
                <Timer onExpire={() => setShowResendOtp(true)} />
              </StyledAlignCenter>
              {resendRes.isError ? (
                <ErrorMessage show={resendRes.isError}>
                  {resendOtpError.email || resendOtpError.general}
                </ErrorMessage>
              ) : null}
            </div>
          )}

          <StyledLink to="/registration/add-email">Change Email</StyledLink>
        </OtpOptions>
      </RegistrationCapsule>
    </>
  );
};

export default OtpVerification;
