import CopyText from "components/common/CopyText/CopyText";
import Button from "components/ui/Button/Button";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  StyledAlignCenter,
  StyledCentering,
} from "styles/common-styled-components";

const NetBankingPaymentRedirect = () => {
  // https://{current_site}/confirm_order?client_id={request.user.bse_client_id}&order_id={order_id}
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const client_id = searchParams.get("client_id") || "N/A";
  const order_id = searchParams.get("order_id") || "N/A";

  useEffect(() => {
    if (order_id) {
      navigate(`/orders/${order_id}`);
    }
  }, []);

  return (
    <StyledCentering style={{ flexDirection: "column" }}>
      <p>Check your payment Status here.</p>
      <StyledAlignCenter style={{ marginBottom: "1rem" }}>
        <p>Reference Number: {order_id}</p>
        <CopyText copyText={order_id} />
      </StyledAlignCenter>
      <Button
        title={"ORDERS"}
        onClick={() => navigate(`/orders/${order_id}`)}
        contStyle={{ width: "300px" }}
      />
    </StyledCentering>
  );
};

export default NetBankingPaymentRedirect;
