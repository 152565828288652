import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { HelpOutlineTwoTone } from "@mui/icons-material/";
import DataRenderer from "components/utility/DataRenderer";
import SipStepIndicator from "./SipStepIdicator";
import { DetailsLabel, DetailsRow, DetailsValue } from "./SipOrderDetails";
import { useNavigate } from "react-router-dom";
import { capitalizeFundName } from "utils/functions";

const SipDetails = () => {
  const { sip_regn_number } = useParams();
  const navigate = useNavigate();

  const getDaySuffix = (day) => {
    if (day === 1) return "st";
    if (day === 2) return "nd";
    if (day === 3) return "rd";
    return "th";
  };

  return (
    <MainContainer>
      <DataRenderer
        apiEndpoint={`/api/sip-allotments/${sip_regn_number}/`}
        privateRequest
        queryName={`active-sip - ${sip_regn_number}`}
        renderData={(response) => {
          const data = response.data;
          if (!data) return <p>Loading...</p>;
          if (data.error) return <p>Error: {data.error.message}</p>;

          const {
            instalment_amount,
            day,
            scheme_name,
            logo_url,
            sip_registration_number,
            folio_number,
            sip_journey,
          } = data;

          const formattedDate = `${day || ""}${getDaySuffix(
            day
          )} of every Month`;

          return (
            <ContentWrapper>
              <LeftSection>
                <Card>
                  <AmountSection>
                    <LogoContainer>
                      {" "}
                      {logo_url && (
                        <img
                          src={logo_url}
                          alt={`${scheme_name} Logo`}
                          style={{ width: "100%", height: "100%" }}
                        />
                      )}
                    </LogoContainer>
                    <h2>₹{instalment_amount || "N/A"}</h2>
                    <p>{formattedDate}</p>
                  </AmountSection>
                  <Title>
                    {capitalizeFundName(scheme_name) ||
                      "No Scheme Name Available"}
                  </Title>
                </Card>

                <Container>
                  <DetailsGrid>
                    <DetailsRow>
                      <DetailsLabel>SIP Registration Number:</DetailsLabel>
                      <DetailsValue>
                        {sip_registration_number || "N/A"}
                      </DetailsValue>
                    </DetailsRow>
                    <DetailsRow>
                      <DetailsLabel>Folio Number:</DetailsLabel>
                      <DetailsValue>{folio_number || "N/A"}</DetailsValue>
                    </DetailsRow>
                  </DetailsGrid>
                </Container>

                <HelpContainer>
                  <HelpText>Have questions about this order?</HelpText>
                  <HelpText
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/help");
                    }}
                  >
                    <HelpOutlineTwoTone />
                    Get help
                  </HelpText>
                </HelpContainer>
              </LeftSection>

              <RightSection>
                <p style={{ borderBottom: "1px solid #ddd", padding: "16px" }}>
                  Instalments
                </p>
                <div style={{ padding: "16px" }}>
                  {sip_journey && sip_registration_number ? (
                    <SipStepIndicator
                      sipJourney={sip_journey}
                      sip_regn_number={sip_registration_number}
                      sipDetails={data}
                    />
                  ) : (
                    <p>No instalments available</p>
                  )}
                </div>
              </RightSection>
            </ContentWrapper>
          );
        }}
      />
    </MainContainer>
  );
};

export default SipDetails;

const MainContainer = styled.div`
  @media (max-width: 400px) {
    margin-top: 60px;
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 980px) {
    margin-top: 6rem;
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.7;
  gap: 30px;
`;

const RightSection = styled.div`
  flex: 0.3;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
`;

const Container = styled.div`
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
`;
const LogoContainer = styled.div`
  margin-bottom: 0.8px;
  height: 4.8rem;
  width: 4.8rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  padding: 6px;
`;

const AmountSection = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  margin: 8px 0;
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 70px;
  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .label {
    font-size: 12px;
    font-weight: 400;
    color: #757575;
  }

  .value {
    font-size: 14px;
    font-weight: 400;
    color: #333;
  }
`;

const HelpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  background-color: #e4faf9;
  padding: 16px;
  border-radius: 8px;
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const HelpText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
`;
