import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  StyledAlignCenter,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components.js";
import {
  Container,
  ContLeft,
  OrderCard,
  OrdercardTop,
} from "./Payment.styles.jsx";
import MobileNav from "components/common/MobileNav/MobileNav.jsx";
import { useSelector } from "react-redux";
import { API_ENDPOINTS } from "utils/constants.js";
import {
  capitalizeFundName,
  convertNumberToCurrency,
} from "utils/functions.js";
import useDocumentTitle from "hooks/useDocumentTitle.js";
import { useEffect } from "react";
import { ChevronLeft } from "@mui/icons-material";
import DataRenderer from "components/utility/DataRenderer.jsx";
import PaymentInfo from "./PaymentInfo.jsx";
import MiniLoader from "components/common/MiniLoader/MiniLoader.jsx";

const Payment = () => {
  const { state: accumulatedPayload } = useLocation();
  const [vpa, setVpa] = useState("");

  useDocumentTitle(
    `Payment for ${capitalizeFundName(accumulatedPayload.name)}${
      accumulatedPayload?.folio_number ? " - Additional Purchase" : null
    }`
  );
  const user = useSelector((state) => state.user.userDetails);

  const bank_options = user?.bankdetail?.map((curr) => {
    return {
      label: `${curr.bank_name} (${"X".repeat(
        curr.account_number.length - 4
      )}${curr.account_number.substr(curr.account_number.length - 4)})`,
      tag: curr.default ? "Primary" : "",
      value: curr.id,
    };
  });

  let defaultBank = user.bankdetail.filter((curr) => curr.default);
  if (defaultBank.length === 0) {
    defaultBank = user.bankdetail;
  }

  const [selectedBankForPayment, setSelectedBankForPayment] = useState(
    defaultBank[0].id
  );

  const navigate = useNavigate();

  const [paymentOption, setPaymentOption] = useState("upi");

  const changeInvestmentDetails = () => {
    if (accumulatedPayload?.folio_number) {
      navigate(`/investments`, {
        state: {
          dateValue: parseInt(accumulatedPayload?.monthlySipDate),
          amount: +accumulatedPayload?.amount,
          investmentType: accumulatedPayload?.investmentType,
        },
      });
    } else {
      navigate(`/mutual-funds/${accumulatedPayload.id}`, {
        state: {
          dateValue: parseInt(accumulatedPayload.monthlySipDate),
          amount: +accumulatedPayload.amount,
          investmentType: accumulatedPayload.investmentType,
        },
      });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Container>
      <MobileNav
        headerTitle={`${
          accumulatedPayload.investmentType === "sip" ? "SIP" : "LUMPSUM"
        } Investment`}
      />
      <ContLeft>
        <OrderCard>
          <OrdercardTop style={{}}>
            <p style={{ fontWeight: "600" }}>
              {capitalizeFundName(accumulatedPayload.name)}
            </p>
            {accumulatedPayload.folio_number ? (
              <p>(Additional Purchase)</p>
            ) : null}
          </OrdercardTop>

          <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
            <p style={{ color: "#666" }}>Amount</p>
            <p style={{ fontWeight: "600" }}>
              {convertNumberToCurrency(Number(accumulatedPayload.amount))}
            </p>
          </StyledSpaceBetweenAlignCenter>
          <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
            <p style={{ color: "#666" }}>Scheme type</p>
            <p style={{ fontWeight: "600" }}>
              {accumulatedPayload.investmentType === "sip" ? "SIP" : "LUMPSUM"}
            </p>
          </StyledSpaceBetweenAlignCenter>
          {accumulatedPayload.investmentType === "sip" ? (
            <StyledSpaceBetweenAlignCenter style={{ padding: "0 2rem" }}>
              <p style={{ color: "#666" }}>Monthly SIP Date</p>
              <p style={{ fontWeight: "600" }}>
                {accumulatedPayload.monthlySipDate}
              </p>
            </StyledSpaceBetweenAlignCenter>
          ) : null}
        </OrderCard>
        {accumulatedPayload.comingFromOrders ? null : (
          <StyledAlignCenter
            onClick={changeInvestmentDetails}
            style={{
              marginBottom: "1rem",
              color: "var(--secondary)",
              cursor: "pointer",
            }}
          >
            <ChevronLeft style={{ fontSize: "2.4rem" }} />
            <span>Change Details</span>
          </StyledAlignCenter>
        )}
      </ContLeft>
      <DataRenderer
        privateRequest
        apiEndpoint={API_ENDPOINTS.paymentInstruments}
        queryName={"payment-instruments"}
        renderLoading={() => {
          return (
            <div style={{ flex: 2 }}>
              <MiniLoader />
            </div>
          );
        }}
        renderData={(data) => {
          return (
            <PaymentInfo
              accumulatedPayload={accumulatedPayload}
              bank_options={bank_options}
              selectedBankForPayment={selectedBankForPayment}
              setSelectedBankForPayment={setSelectedBankForPayment}
              paymentOption={paymentOption}
              setPaymentOption={setPaymentOption}
              data={data}
              vpa={vpa}
              setVpa={setVpa}
            />
          );
        }}
      />
    </Container>
  );
};

export default Payment;
