import React, { useEffect } from "react";
import { NetBankingForm, UPIForm } from "./Components/Forms";
import RadioComp from "components/ui/RadioComp/RadioComp.jsx";
import { ContRight, PaymentCont } from "./Payment.styles.jsx";
import Select from "components/ui/Select/Select.jsx";
import MandateAuthorizationPopup from "./MandateAuthorizationPopup.jsx";
import { StyledAlignCenter } from "styles/common-styled-components";
import Image from "components/ui/Image/Image.jsx";
import bhim from "assets/logos/upi.png";

//  if (payment_instruments_data.data.last_used === "upi") {
//             setPaymentOption(payment_instruments_data.data.last_used);
//           } else if (payment_instruments_data.data.last_used === "netbanking") {
//             setPaymentOption("net-banking");
//           } else {
//             setPaymentOption("upi");
//           }
const PaymentInfo = ({
  accumulatedPayload,
  bank_options,
  selectedBankForPayment,
  setSelectedBankForPayment,
  paymentOption,
  setPaymentOption,
  data,
  vpa,
  setVpa,
}) => {
  useEffect(() => {
    if (data.data.last_used === "netbanking") {
      setPaymentOption("netbanking");
      setSelectedBankForPayment(data.data.netbanking.bank_id);
    } else {
      setVpa(data.data.upi.vpa);
      setPaymentOption("upi");
      setSelectedBankForPayment(data.data.upi.bank_id);
    }
  }, [data, setVpa, setPaymentOption, setSelectedBankForPayment]);
  return (
    <>
      <ContRight>
        <PaymentCont>
          <p style={{ fontSize: "2rem", fontWeight: "600" }}>Payment</p>
          <p style={{ marginBottom: "3rem" }}>
            {accumulatedPayload.comingFromOrders
              ? ""
              : "To confirm your order, we will send you an OTP on registered mobile number."}
          </p>
          <Select
            options={bank_options}
            label={"Select Bank account"}
            value={selectedBankForPayment}
            onChange={setSelectedBankForPayment}
          />
          {accumulatedPayload.investmentType === "sip" ? (
            <MandateAuthorizationPopup
              selectedBankForPayment={selectedBankForPayment}
            />
          ) : null}

          <p
            style={{
              fontSize: "1.6rem",
              marginTop: "3rem",
              marginBottom: "1rem",
            }}
          >
            Payment method
          </p>
          {/* <p
            onClick={() => {
              setVpa(vpa);
            }}
          >
            {vpa}
          </p> */}
          <RadioComp
            selected={paymentOption === "upi"}
            label={
              <StyledAlignCenter>
                <Image size={"2.4rem"} src={bhim} alt="upi logo" />
                <p>UPI</p>
              </StyledAlignCenter>
            }
            onChange={() => setPaymentOption("upi")}
          >
            <UPIForm
              selectedBankForPayment={selectedBankForPayment}
              vpa={vpa}
            />
          </RadioComp>
          <StyledAlignCenter
            style={{
              gap: "2rem",
              margin: "2rem 0",
            }}
          >
            <div
              style={{
                height: "1px",
                backgroundColor: "#efefef",
                width: "100%",
              }}
            />
            <div>or</div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#efefef",
                width: "100%",
              }}
            />
          </StyledAlignCenter>
          <RadioComp
            selected={paymentOption === "netbanking"}
            label={"Net Banking"}
            onChange={() => setPaymentOption("netbanking")}
          >
            <NetBankingForm selectedBankForPayment={selectedBankForPayment} />
          </RadioComp>
        </PaymentCont>
      </ContRight>
    </>
  );
};

export default PaymentInfo;
