import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { AddCircleOutline, Close, Share } from "@mui/icons-material";
import { CloseButton, StyledCentering } from "styles/common-styled-components";

import { AlertContext } from "App";
import SearchMutualFunds from "components/global/SearchMutualFunds/SearchMutualFunds";

import * as styles from "./CompareFunds.styles";
import { publicAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import { useSearchParams, useNavigate } from "react-router-dom";
import { capitalizeFundName, convertNumberToCurrency } from "utils/functions";

import styled from "styled-components";
import { COMP_DIMENSIONS } from "utils/constants";
import Button from "components/ui/Button/Button";
import CategoryAccordion from "./CompareFunds.sub";
import Image from "components/ui/Image/Image";
import ShareComp from "pages/SingleMutualFund/_ShareComp";
import MobileNav from "components/common/MobileNav/MobileNav";
import SyncLoader from "react-spinners/SyncLoader";

const COLORS = ["#8884d8", "#82ca9d", "#ffc658"];

const GraphCont = styled.div`
  width: 100%;
  height: 100%;
`;

const NoGraphDataCont = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #666;
    font-size: 14px;
  }
`;

const TooltipContainer = styled.div`
  background: white;
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const DateLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
`;

const Value = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;
  font-weight: 500;
`;
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: "100%";
  text-align: center;
`;

const LoadingText = styled.p`
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #555;
`;

const FundComparisonChart = ({ selectedFundsData }) => {
  const [timeRange, setTimeRange] = useState("all");
  const graphButtons = [
    { label: "1M", value: 1 },
    { label: "6M", value: 6 },
    { label: "1Y", value: 12 },
    { label: "3Y", value: 36 },
    { label: "5Y", value: 60 },
    { label: "ALL", value: "all" },
  ];

  const convertToDate = (d) => {
    const [day, month, year] = d.split("-");
    return new Date(year, month - 1, day);
  };

  const filterByTimeRange = (data) => {
    if (timeRange === "all") return data;

    const currentDate = new Date();
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - timeRange,
      currentDate.getDate()
    );

    return data.filter((entry) => convertToDate(entry.date) >= startDate);
  };

  const combinedData = {};
  selectedFundsData.forEach((fund, index) => {
    if (!fund?.graph) return;

    fund.graph.forEach((point) => {
      const dateStr = point.date;
      if (!combinedData[dateStr]) {
        combinedData[dateStr] = { date: dateStr };
      }
      combinedData[dateStr][`fund${index}`] = point.nav_price;
    });
  });

  const chartData = filterByTimeRange(
    Object.values(combinedData).sort(
      (a, b) => convertToDate(a.date) - convertToDate(b.date)
    )
  );

  const allNavPrices = chartData.flatMap((entry) =>
    selectedFundsData
      .map((fund, index) => entry[`fund${index}`])
      .filter(Boolean)
  );

  const maxNavPrice = Math.max(...allNavPrices);
  const minNavPrice = Math.min(...allNavPrices);

  const tickStep = Math.max(1, Math.ceil((maxNavPrice - minNavPrice) / 10));

  const ticks = Array.from(
    { length: Math.ceil((maxNavPrice - minNavPrice) / tickStep) + 1 },
    (_, i) => (i * tickStep + minNavPrice).toFixed(1)
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <TooltipContainer>
          <DateLabel>{label}</DateLabel>
          {payload.map((entry, index) => {
            const fundIndex = parseInt(entry.dataKey.slice(-1));
            const fundName = selectedFundsData[fundIndex]?.mf?.name || "Fund";
            const fundColor = COLORS[fundIndex];

            return (
              <Value key={index}>
                <styles.ColorIndicator style={{ backgroundColor: fundColor }} />
                {capitalizeFundName(fundName)}: ₹{entry.value}
              </Value>
            );
          })}
        </TooltipContainer>
      );
    }
    return null;
  };

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
  };

  return (
    <GraphCont>
      <ResponsiveContainer height={COMP_DIMENSIONS.HEIGHTS.graph}>
        {!chartData.length ? (
          <NoGraphDataCont>
            <p>No Graph Data Available</p>
          </NoGraphDataCont>
        ) : (
          <LineChart data={chartData}>
            <YAxis
              hide={true}
              type="number"
              domain={[minNavPrice, maxNavPrice + 1]}
              allowDataOverflow={true}
              ticks={ticks}
            />
            <XAxis dataKey="date" hide={true} />
            {selectedFundsData.map(
              (fund, index) =>
                fund?.graph && (
                  <Line
                    key={index}
                    type="monotone"
                    dataKey={`fund${index}`}
                    stroke={COLORS[index]}
                    strokeWidth={1}
                    dot={false}
                  />
                )
            )}
            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        )}
      </ResponsiveContainer>
      {chartData.length > 0 && (
        <styles.ButtonGroup>
          {graphButtons.map((button) => (
            <styles.TimeRangeButton
              key={button.value}
              onClick={() => handleTimeRangeChange(button.value)}
              active={timeRange === button.value}
            >
              {button.label}
            </styles.TimeRangeButton>
          ))}
        </styles.ButtonGroup>
      )}
    </GraphCont>
  );
};

const MAX_FUNDS = 3;

const CompareFunds = () => {
  const { openModal, closeModal } = useContext(AlertContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [funds, setFunds] = useState([]);
  const [selectedFundsData, setSelectedFundsData] = useState(
    Array(MAX_FUNDS).fill(null)
  );
  const [loading, setLoading] = useState(Array(MAX_FUNDS).fill(false));

  const fetchedFundsRef = useRef(new Set());

  const ongoingFetchesRef = useRef(new Map());

  const fetchFundData = async (fundId) => {
    try {
      const mf = await publicAxios
        .get(API_ENDPOINTS.getMFDetails(fundId))
        .then((res) => res.data);
      const schemeCode = mf?.scheme_code;
      if (!schemeCode) {
        console.error("Scheme code not found for fundId:", fundId);
        return null;
      }

      const [graph, additionalMf] = await Promise.all([
        publicAxios
          .get(API_ENDPOINTS.getGraphData, {
            params: { scheme_code: schemeCode },
          })
          .then((res) => res.data),
        publicAxios
          .get(API_ENDPOINTS.schemeDetails(fundId))
          .then((res) => res.data),
      ]);

      console.log({ mf, additionalMf });
      return { graph, mf, additionalMf };
    } catch (error) {
      console.error(`Error fetching data for fundId ${fundId}:`, error);
      return null;
    }
  };

  const fetchSingleFund = useCallback(async (fundId, index) => {
    if (!fundId || loading[index] || fetchedFundsRef.current.has(fundId)) {
      return;
    }

    if (ongoingFetchesRef.current.has(fundId)) {
      return ongoingFetchesRef.current.get(fundId);
    }

    setLoading((prev) => {
      const newLoading = [...prev];
      newLoading[index] = true;
      return newLoading;
    });

    const fetchPromise = fetchFundData(fundId).then((data) => {
      if (data) {
        fetchedFundsRef.current.add(fundId);
        setSelectedFundsData((prev) => {
          const newData = [...prev];
          newData[index] = data;
          return newData;
        });
      }

      setLoading((prev) => {
        const newLoading = [...prev];
        newLoading[index] = false;
        return newLoading;
      });

      // Remove from ongoing fetches when done
      ongoingFetchesRef.current.delete(fundId);
      return data;
    });

    ongoingFetchesRef.current.set(fundId, fetchPromise);
    return fetchPromise;
  }, []);

  useEffect(() => {
    const urlFunds = Array.from({ length: MAX_FUNDS }, (_, i) => {
      const fundId = searchParams.get(`fund${i}`);
      return { fundId, index: i };
    }).filter(({ fundId }) => fundId);

    // Update funds state while preserving structure
    const newFunds = Array(MAX_FUNDS).fill(null);
    urlFunds.forEach(({ fundId, index }) => {
      newFunds[index] = fundId;
    });
    setFunds(newFunds);

    // Only fetch data for funds that haven't been loaded yet
    urlFunds.forEach(({ fundId, index }) => {
      if (fundId && !fetchedFundsRef.current.has(fundId)) {
        fetchSingleFund(fundId, index);
      }
    });
  }, [searchParams, fetchSingleFund]);

  const handleRemoveFund = (index) => {
    // Create new arrays maintaining the original structure
    const updatedFunds = [...funds];
    const updatedSelectedFundsData = [...selectedFundsData];

    // Clear the fund at the specified index
    updatedFunds[index] = null;
    updatedSelectedFundsData[index] = null;

    // Update state
    setFunds(updatedFunds);
    setSelectedFundsData(updatedSelectedFundsData);

    // Create new URL parameters maintaining the order
    const params = new URLSearchParams();
    updatedFunds.forEach((fundId, i) => {
      if (fundId) {
        params.set(`fund${i}`, fundId);
      }
    });

    // Update URL with new parameters
    setSearchParams(params);
  };

  const handleAddFund = (index) => {
    openModal({
      comp: (
        <div>
          <CloseButton onClick={closeModal} />
          <StyledCentering style={{ marginTop: "2rem" }}>
            <div style={{ padding: "2rem", height: "calc(55vh + 4rem)" }}>
              <SearchMutualFunds
                isMobileSearch
                onSelect={async (fundId) => {
                  const existingIndex = Array.from(
                    { length: MAX_FUNDS },
                    (_, i) => searchParams.get(`fund${i}`)
                  ).findIndex((id) => id === fundId.toString());

                  console.log(
                    "A",
                    fundId.toString(),
                    Array.from({ length: MAX_FUNDS }, (_, i) =>
                      searchParams.get(`fund${i}`)
                    ),
                    existingIndex
                  );

                  if (existingIndex > -1) {
                    // Show error message using Alert from shadcn/ui
                    openModal({
                      comp: (
                        <div style={{ padding: "1.2rem" }}>
                          <CloseButton onClick={closeModal} />
                          <h2
                            style={{
                              marginTop: "1.2rem",
                              marginBottom: "0.6rem",
                            }}
                          >
                            Fund Already Added
                          </h2>
                          <p>
                            This fund is already in your comparison. Please
                            select a different fund.
                          </p>
                        </div>
                      ),
                      style: { maxWidth: "300px", minWidth: "300px" },
                    });
                    return;
                  }

                  const params = new URLSearchParams(searchParams);
                  params.set(`fund${index}`, fundId);
                  setSearchParams(params);

                  setLoading((prev) => {
                    const newLoading = [...prev];
                    newLoading[index] = true;
                    return newLoading;
                  });

                  setFunds((prev) => {
                    const updatedFunds = [...prev];
                    updatedFunds[index] = fundId;
                    return updatedFunds;
                  });

                  closeModal();
                  const fundData = await fetchFundData(fundId);

                  setSelectedFundsData((prev) => {
                    const updatedData = [...prev];
                    updatedData[index] = fundData;
                    return updatedData;
                  });

                  setLoading((prev) => {
                    const newLoading = [...prev];
                    newLoading[index] = false;
                    return newLoading;
                  });
                }}
                onClose={closeModal}
              />
            </div>
          </StyledCentering>
        </div>
      ),
      style: { maxHeight: "75vh", maxWidth: "50vw" },
    });
  };

  return (
    <>
      <MobileNav headerTitle={"Compare funds"} />
      <styles.Container>
        <styles.Header>
          <styles.MetricBoxLabel>
            {funds.filter((curr) => curr).length > 1 ? (
              <div>
                <Share
                  onClick={() =>
                    openModal({
                      comp: (
                        <ShareComp
                          title={"Share this comparision link"}
                          closeModal={closeModal}
                          shareMessage={`Check out this comparision of ${selectedFundsData
                            .map((curr) => {
                              if (curr) {
                                return curr.mf.name;
                              } else {
                                return null;
                              }
                            })
                            .join(", ")}} on WiseBulls`}
                          name={``}
                        />
                      ),
                      animationStyle: "slideFromBottom",
                      style: { minWidth: "30rem" },
                    })
                  }
                  style={{ color: "var(--themeColor)", cursor: "pointer" }}
                  data-tooltip-id="share"
                  data-tooltip-content={"Share this comparision"}
                />
                <ReactTooltip id="share" />
              </div>
            ) : (
              <div>
                <Share
                  style={{ color: "#ccc" }}
                  data-tooltip-id="share"
                  data-tooltip-content={"Please add atleast 2 funds to share"}
                />
                <ReactTooltip id="share" style={{ zIndex: 1000 }} />
              </div>
            )}

            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "600",
                marginBottom: "4px",
                lineHeight: "2.4rem",
              }}
            >
              Fund Comparison & Analysis
            </p>
            <p>Key Metrics: NAV, Returns, Risk, Rating & Insights</p>
          </styles.MetricBoxLabel>

          {Array.from({ length: MAX_FUNDS }).map((_, i) => (
            <styles.MetricBoxValue
              key={i}
              style={{
                cursor: "pointer",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => !loading[i] && handleAddFund(i)}
            >
              {loading[i] ? (
                <LoaderContainer>
                  <SyncLoader color={"var(--themeColor)"} size={8} />
                  <LoadingText>Fetching Fund Details</LoadingText>
                </LoaderContainer>
              ) : funds[i] ? (
                selectedFundsData[i]?.mf?.name ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "4px",
                      }}
                    >
                      <Image
                        src={selectedFundsData[i]?.mf?.logo_url}
                        size={"2.4rem"}
                      />
                      <div
                        style={{
                          padding: "3px",
                          paddingBottom: 0,
                          backgroundColor: "var(--light-grey)",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFund(i);
                        }}
                      >
                        <Close style={{ fontSize: "1.2rem" }} />
                      </div>
                    </div>

                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {capitalizeFundName(selectedFundsData[i]?.mf?.name) ||
                          "Could not load the data"}
                      </p>
                    </div>
                    <Button
                      contStyle={{ alignSelf: "flex-end" }}
                      title={"Invest"}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(
                          `/mutual-funds/${selectedFundsData[i]?.mf?.id}`
                        );
                      }}
                    />
                  </div>
                ) : (
                  "Could not load the data"
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <AddCircleOutline style={{ fontSize: "2.4rem" }} />
                  <p style={{ fontSize: "1.6rem", fontWeight: "500" }}>
                    Add Fund
                  </p>
                </div>
              )}
            </styles.MetricBoxValue>
          ))}
        </styles.Header>
        <CategoryAccordion
          heading={"Graph"}
          contStyle={{}}
          content={
            <div style={{ width: "100%" }}>
              <FundComparisonChart selectedFundsData={selectedFundsData} />
            </div>
          }
        />
        <CategoryAccordion
          heading={"Fund Overview"}
          content={
            <div>
              {/* Fund Size */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{
                    borderBottom: `1px solid #e9ecef`,
                    borderTop: `1px solid #e9ecef`,
                  }}
                >
                  <p>Fund Size</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{
                      borderBottom: `1px solid #e9ecef`,
                      borderTop: `1px solid #e9ecef`,
                    }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {convertNumberToCurrency(
                          selectedFundsData[i]?.additionalMf?.asset_size
                        )}{" "}
                        Crs
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Category */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Category</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {selectedFundsData[i]?.additionalMf?.scheme_category}
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Expense Ratio */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Expense Ratio</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {selectedFundsData[i]?.additionalMf?.expense_ratio}%
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* NAV (Net Asset Value) */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>NAV</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {convertNumberToCurrency(
                          selectedFundsData[i]?.mf?.nav_latest
                        )}
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Risk */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Risk</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {selectedFundsData[i]?.additionalMf?.riskometer_value}
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>
            </div>
          }
        />

        <CategoryAccordion
          heading={"Returns"}
          content={
            <div>
              {/* 1-Year Return */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{
                    borderBottom: `1px solid #e9ecef`,
                    borderTop: `1px solid #e9ecef`,
                  }}
                >
                  <p>1-Year Return</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{
                      borderBottom: `1px solid #e9ecef`,
                      borderTop: `1px solid #e9ecef`,
                    }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.scheme_performance?.[0]?.one_year_return
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* 3-Year Return */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>3-Year Return</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.scheme_performance?.[0]?.three_year_return
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* 5-Year Return */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>5-Year Return</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.scheme_performance?.[0]?.five_year_return
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* 10-Year Return */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>10-Year Return</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.scheme_performance?.[0]?.ten_year_return
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Since Inception Return */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Since Inception</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.scheme_performance?.[0]?.inception_year_return
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>
            </div>
          }
        />

        <CategoryAccordion
          heading={"Portfolio"}
          content={
            <div>
              {/* Asset Allocations */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{
                    borderBottom: `1px solid #e9ecef`,
                    borderTop: `1px solid #e9ecef`,
                  }}
                >
                  <p>Asset Allocations</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{
                      borderBottom: `1px solid #e9ecef`,
                      borderTop: `1px solid #e9ecef`,
                    }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <div
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                          textAlign: "left",
                        }}
                      >
                        {selectedFundsData[
                          i
                        ]?.additionalMf?.asset_allocations?.map(
                          (asset, index) => (
                            <p key={index}>
                              {asset?.asset_type}{" "}
                              <span style={{ color: "#6c757d" }}>
                                (
                                {typeof asset?.asset_percentage === "string"
                                  ? Number(asset?.asset_percentage).toFixed(2)
                                  : "0.00"}
                                %)
                              </span>
                            </p>
                          )
                        )}
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Top 3 Sectors */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Top 3 Sectors</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => {
                  const filteredSectors = selectedFundsData[
                    i
                  ]?.additionalMf?.sector_allocations
                    ?.filter(
                      (sector) =>
                        sector?.sector_name &&
                        sector?.sector_name !== "N/A" &&
                        sector?.sector_name.trim() !== "" &&
                        sector?.sector_percentage !== undefined
                    )
                    .slice(0, 3);

                  return (
                    <styles.MetricBoxValue
                      key={i}
                      style={{ borderBottom: `1px solid #e9ecef` }}
                      onClick={() => !loading[i] && handleAddFund(i)}
                    >
                      {loading[i] ? null : funds[i] ? (
                        <div
                          style={{
                            fontSize: "1.3rem",
                            fontWeight: "500",
                            marginBottom: "4px",
                            textAlign: "left",
                          }}
                        >
                          {filteredSectors && filteredSectors.length > 0 ? (
                            filteredSectors.map((sector, index) => (
                              <p key={index}>
                                {sector.sector_name}{" "}
                                <span style={{ color: "#6c757d" }}>
                                  (
                                  {typeof sector.sector_percentage === "string"
                                    ? Number(sector.sector_percentage).toFixed(
                                        2
                                      )
                                    : "0.00"}
                                  %)
                                </span>
                              </p>
                            ))
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </styles.MetricBoxValue>
                  );
                })}
              </styles.TopRow>

              {/* Top 5 Holdings */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Top 5 Holdings</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => {
                  const filteredHoldings = selectedFundsData[
                    i
                  ]?.additionalMf?.portfolio_holdings
                    ?.filter(
                      (holding) =>
                        holding?.company_name &&
                        holding?.holding_percentage !== undefined
                    )
                    .slice(0, 5);

                  return (
                    <styles.MetricBoxValue
                      key={i}
                      style={{ borderBottom: `1px solid #e9ecef` }}
                      onClick={() => !loading[i] && handleAddFund(i)}
                    >
                      {loading[i] ? null : funds[i] ? (
                        <div
                          style={{
                            fontSize: "1.3rem",
                            fontWeight: "500",
                            marginBottom: "4px",
                          }}
                        >
                          {filteredHoldings && filteredHoldings.length > 0 ? (
                            filteredHoldings.map((holding, index) => (
                              <p key={index}>
                                {holding?.company_name}{" "}
                                <span style={{ color: "#6c757d" }}>
                                  (
                                  {typeof holding?.holding_percentage ===
                                  "string"
                                    ? Number(
                                        holding?.holding_percentage
                                      ).toFixed(2)
                                    : "0.00"}
                                  %)
                                </span>
                              </p>
                            ))
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      ) : (
                        <div>-</div>
                      )}
                    </styles.MetricBoxValue>
                  );
                })}
              </styles.TopRow>
            </div>
          }
        />

        <CategoryAccordion
          heading={"Risk Measures"}
          content={
            <div>
              {/* Volatility (3Y) */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{
                    borderBottom: `1px solid #e9ecef`,
                    borderTop: `1px solid #e9ecef`,
                  }}
                >
                  <p>Volatility (3Y)</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{
                      borderBottom: `1px solid #e9ecef`,
                      borderTop: `1px solid #e9ecef`,
                    }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.volatility_cm_3year
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Sharpe Ratio (3Y) */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Sharpe Ratio (3Y)</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.sharpratio_cm_3year
                        }
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Alpha (1Y) */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Alpha (1Y)</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.alpha_cm_1year
                        }
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Beta (1Y) */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Beta (1Y)</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.beta_cm_1year
                        }
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Yield to Maturity */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Yield to Maturity</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.yield_to_maturity
                        }
                        %
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Average Maturity */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Average Maturity</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.average_maturity
                        }
                        years
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>

              {/* Sortino Ratio */}
              <styles.TopRow>
                <styles.MetricBoxLabel
                  style={{ borderBottom: `1px solid #e9ecef` }}
                >
                  <p>Sortino Ratio</p>
                </styles.MetricBoxLabel>
                {Array.from({ length: MAX_FUNDS }).map((_, i) => (
                  <styles.MetricBoxValue
                    key={i}
                    style={{ borderBottom: `1px solid #e9ecef` }}
                    onClick={() => !loading[i] && handleAddFund(i)}
                  >
                    {loading[i] ? null : funds[i] ? (
                      <p
                        style={{
                          fontSize: "1.3rem",
                          fontWeight: "500",
                          marginBottom: "4px",
                        }}
                      >
                        {
                          selectedFundsData[i]?.additionalMf
                            ?.risk_statistics?.[0]?.shortino_ratio
                        }
                      </p>
                    ) : (
                      <div>-</div>
                    )}
                  </styles.MetricBoxValue>
                ))}
              </styles.TopRow>
            </div>
          }
        />
      </styles.Container>
    </>
  );
};

export default CompareFunds;
