import React, { useState } from "react";

import wiseBullsLogo from "assets/logos/logo.png";
import { useSelector } from "react-redux";
import { UnderLineButton } from "styles/common-styled-components";
import PinInput from "components/common/PinInput/PinInput";
import { PINCard, PageBg } from "./PIN.styles";
import { useMutation } from "react-query";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import { secondsToMinutesAndSeconds } from "utils/functions";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import LogoutFunctionality from "components/ui/LogoutFunctionality/LogoutFunctionality";

const PIN = ({ setShowApp }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const userDetails = useSelector((state) => state.user.userDetails);

  const handleVerifyingPin = (pin) => {
    return credentialAxios.post(API_ENDPOINTS.verifyPin, {
      pin,
    });
  };

  const onVerificationSuccess = (res) => {
    if (res.data.pin_verified) {
      setShowApp(true);
      sessionStorage.setItem("verified_pin", "true");
      navigate("/");
    }
  };

  const onVerificationError = (error) => {
    if (error?.response?.data?.pin_verified === false) {
      if (3 - error?.response?.data?.failed_attempts === 0) {
        setError(`Limit reached for Wrong PIN. Try again in 10 minutes`);
      } else {
        setError(
          `Wrong PIN. ${3 - error?.response?.data?.failed_attempts} attempt${
            3 - error?.response?.data?.failed_attempts > 1 ? "s are" : " is"
          }  left`
        );
      }
    } else if (error?.response?.data?.message) {
      const { minutes } = secondsToMinutesAndSeconds(
        error.response.data.time_left
      );
      setError(
        `Limit reached for Wrong PIN. Try again in ${minutes + 1} minute${
          minutes + 1 > 1 ? "s" : ""
        }`
      );
    }
  };

  const { mutate, isLoading: isVerifying } = useMutation(handleVerifyingPin, {
    onSuccess: onVerificationSuccess,
    onError: onVerificationError,
  });

  return (
    <PageBg>
      <PINCard style={{}}>
        <div style={{ height: "10rem", width: "10rem", margin: "2rem" }}>
          <img
            src={wiseBullsLogo}
            alt="WiseBulls Logo"
            style={{ width: "100%", height: "auto", objectFit: "contain" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <p
            style={{
              fontSize: "1.4rem",
              fontWeight: "600",
              marginBottom: "1rem",
            }}
          >
            {userDetails?.email}
          </p>
          <LogoutFunctionality>
            <UnderLineButton style={{ marginBottom: "4rem" }}>
              Logout
            </UnderLineButton>
          </LogoutFunctionality>

          <p
            style={{
              fontWeight: "900",
              fontSize: "2.4rem",
              marginBottom: "1rem",
            }}
          >
            Enter PIN
          </p>
          <p style={{ marginBottom: "3.4rem" }}>
            Verify 4-digit WiseBulls security PIN
          </p>
          {isVerifying ? (
            <SyncLoader
              color={"var(--themeColor)"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
              style={{ marginBottom: "1rem" }}
            />
          ) : (
            <PinInput onComplete={(pin) => mutate(pin)} />
          )}
        </div>
        <ErrorMessage message={error} show={error} />
      </PINCard>
    </PageBg>
  );
};

export default PIN;
