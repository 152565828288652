import styled from "styled-components/macro";
import { AlignCenter } from "styles/common-styles";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const NumberLink = styled.a`
  font-size: 2rem;
  color: var(--themeColor);
  font-weight: 400;
  display: block;
  margin-bottom: 2rem;
  text-decoration: none;
`;

export const ImgContainer = styled.div`
  height: auto;
  width: 30rem;
`;
export const Box = styled.div`
  height: 15rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-light-grey);
  border-radius: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: aliceblue;
`;
export const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  background: var(--themeColorShade1);
  width: 100%;
  padding: 5rem;
  margin-top: 2rem;
  @media (max-width: 980px) {
    padding: 1.5rem;
    flex-direction: column;
  }
`;
