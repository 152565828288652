import React, { useState } from "react";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "components/ui/Button/Button";
import InputField from "components/ui/InputField/InputField";
import { publicAxios } from "utils/api-calls";
import { API_ENDPOINTS, REGULAR_EXPRESSIONS } from "utils/constants";
import { Form } from "styles/common-styled-components";
import { mapServerErrorsToLocal } from "utils/functions";

const FetchBankFromIfsc = () => {
  const initialErrorState = {
    ifscCode: "",
    general: "",
  };

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    ifscCode: Yup.string()
      .required("IFSC Code is required")
      .matches(REGULAR_EXPRESSIONS.ifsc, "IFSC code is not valid"),
  });

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  const navigateUrl = editMode
    ? "/registration/add-bank?edit_mode=true"
    : "/registration/add-bank";

  const [errorState, setErrorState] = useState(initialErrorState);

  const handleSearchIfscCode = ({ ifscCode }) => {
    return publicAxios.get(API_ENDPOINTS.getBankByIfsc(ifscCode));
  };

  const onError = (error) => {
    const newErrors = mapServerErrorsToLocal(error, initialErrorState, [
      "ifscCode",
    ]);
    setErrorState(newErrors);
  };

  const onSuccess = (res) => {
    navigate(navigateUrl, {
      state: {
        ...res.data,
        nonReg: location.state?.nonReg,
        fromLocation: location.state?.fromLocation,
      },
    });
  };

  const { mutate, isLoading } = useMutation(handleSearchIfscCode, {
    onError,
    onSuccess,
  });

  return (
    <Formik
      initialValues={{
        ifscCode: "",
      }}
      onSubmit={mutate}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleSubmit, handleChange }) => {
        return (
          <Form onSubmit={handleSubmit} style={{ padding: 0 }}>
            <InputField
              value={values.ifscCode.toUpperCase()}
              error={
                touched.ifscCode && (errors.ifscCode || errorState.ifscCode)
              }
              onChange={handleChange("ifscCode")}
              labelInputDirection="vertical"
              autoFocus
            />
            <Button
              style={{ width: "100%" }}
              loading={isLoading}
              title="CONTINUE"
              onClick={() => {
                mutate(values, errors);
              }}
              error={errorState.general}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default FetchBankFromIfsc;
