import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import RegistrationCapsule from "components/ui/RegistrationCapsule/RegistrationCapsule";
import AddBankForm from "components/forms/AddBankForm";
import Table from "components/ui/Table/Table";
import AccordionComp from "components/ui/AccordionComp/AccordionComp";

const AddBank = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  const { userDetails } = useSelector((state) => state.user);

  useEffect(() => {
    if (!location.state?.ifsc_code) {
      navigate("/registration/select-bank");
    }
  }, [location.state?.ifsc_code, navigate]);

  return (
    <>
      <RegistrationCapsule
        heading={`${editMode ? "Edit" : "Add"} your Bank account`}
        para={""}
        fromLocation={
          editMode ? "/registration/select-bank?edit_mode=true" : ""
        }
        afterPara={
          <div style={{ marginBottom: "2rem" }}>
            <AccordionComp
              title={"See selected Bank"}
              content={
                <Table
                  data={{
                    "Bank Name": location.state?.bank_name,
                    "Branch Name": location.state?.branch_name,
                    "Branch City": location.state?.branch_city,
                    "IFSC Code": location.state?.ifsc_code,
                    "Branch State": location.state?.branch_state,
                  }}
                />
              }
            />
          </div>
        }
      >
        <AddBankForm
          ifsc_code={location.state?.ifsc_code}
          editBankId={editMode ? userDetails.bankdetail[0].id : null}
        />
      </RegistrationCapsule>
    </>
  );
};

export default AddBank;
