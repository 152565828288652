import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { credentialAxios } from "utils/api-calls";
import MiniLoader from "components/common/MiniLoader/MiniLoader";
import DashBoardLineChart from "./DashBoardLineChart";
import Allocator from "pages/SingleMutualFund/_Allocater";
import { useLocation, useNavigate } from "react-router-dom";
import { convertNumberToCurrency } from "utils/functions";

const timeRanges = {
  "1M": "/api/insight/net_worth/fetch_graph_data/ONE_MONTH",
  "6M": "/api/insight/net_worth/fetch_graph_data/SIX_MONTHS",
  "1Y": "/api/insight/net_worth/fetch_graph_data/ONE_YEAR",
  ALL: "/api/insight/net_worth/fetch_graph_data/ALL",
};

const Dashboard = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totals, setTotals] = useState({ currentAmount: 0, investedAmount: 0 });
  const [graphData, setGraphData] = useState([]);
  const [selectedRange, setSelectedRange] = useState("ALL");
  const [allocaterData, setAllocaterData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPortfolioData = async () => {
      try {
        const response = await credentialAxios(
          "/api/insight/category_distribution/"
        );
        const data = response.data;

        if (data?.length) {
          setPortfolioData(data);

          const calculatedTotals = data.reduce(
            (acc, category) => {
              category.holdings.forEach((holding) => {
                acc.currentAmount += parseFloat(holding.current_amount);
                acc.investedAmount += parseFloat(holding.invested_amount);
                acc.returns += parseFloat(holding.returns);
              });
              return acc;
            },
            { currentAmount: 0, investedAmount: 0, returns: 0 }
          );
          setTotals(calculatedTotals);

          const mappedAllocaterData = data.map((category) => ({
            category: category.category,
            percentage: category.percentage,
            sub_heading: category.sub_heading,
          }));
          setAllocaterData(mappedAllocaterData);
        } else {
          setPortfolioData([]);
        }
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
        setError("Failed to fetch portfolio data.");
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolioData();
  }, []);

  useEffect(() => {
    const fetchGraphData = async () => {
      setLoading(true);
      try {
        const response = await credentialAxios(timeRanges[selectedRange]);
        console.log("Graph data response:", response.data);

        const data = response.data;

        if (Array.isArray(data)) {
          const filteredData = data.filter(
            (item) => parseFloat(item.netWorth) > 0
          );

          const mappedGraphData = filteredData.map((item) => ({
            date: new Date(item.epoch * 1000).toLocaleDateString(),
            currentAmount: parseFloat(item.netWorth),
            investedAmount: parseFloat(item.investedAmount),
          }));

          setGraphData(mappedGraphData);
        }
      } catch (error) {
        console.error("Error fetching graph data:", error);
        setError("Failed to fetch graph data.");
      } finally {
        setLoading(false);
      }
    };

    setGraphData([]);
    fetchGraphData();
  }, [selectedRange]);

  //   const graphData = [
  //     { date: "2023-11-30", currentAmount: 0, investedAmount: 0 },
  //     { date: "2023-12-01", currentAmount: 1100, investedAmount: 1000 },
  //     { date: "2023-12-02", currentAmount: 1000, investedAmount: 800 },
  //     { date: "2023-12-03", currentAmount: 1500, investedAmount: 1000 },
  //   ];
  const updatedGraphData = graphData.map((dataPoint) => {
    const { currentAmount, investedAmount } = dataPoint;
    const dailyReturn = currentAmount - investedAmount;

    return {
      ...dataPoint,
      dailyReturn,
    };
  });

  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <DashboardContainer>
      <HeadingContainer>
        <p>Portfolio Analysis</p>
        <p
          style={{
            margin: "5px 0 0 0",
            height: "7px",
            width: "40px",
            backgroundColor: "#20b2aa",
          }}
        ></p>
      </HeadingContainer>
      <Container>
        <LeftContainer>
          <TopContainer>
            <div style={{ flex: 1 }}>
              <Header>
                {["currentAmount", "investedAmount", "returns"].map((key) => {
                  const isRed =
                    (key === "currentAmount" &&
                      totals["currentAmount"] < totals["investedAmount"]) ||
                    (key === "returns" && totals["returns"] < 0);

                  const style = {
                    fontSize: "2rem",
                    fontWeight: "500",
                    color: isRed
                      ? "red"
                      : key === "returns" && totals["returns"] > 0
                      ? "#20b2aa"
                      : key === "currentAmount"
                      ? "#20b2aa"
                      : "#000",
                  };

                  return (
                    <AmountContainer key={key}>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p>
                          {key === "currentAmount"
                            ? "Current"
                            : key === "investedAmount"
                            ? "Invested"
                            : "Returns"}
                        </p>
                        <h2
                          style={{
                            ...style,
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                            maxWidth: "100%",
                          }}
                        >
                          {totals[key]
                            ? convertNumberToCurrency(totals[key].toFixed(2))
                            : "0.00"}
                        </h2>
                      </div>
                      <LineCHart showGraph={key === "currentAmount"}>
                        <DashBoardLineChart
                          data={updatedGraphData}
                          fromMonthsBefore={selectedRange}
                          height={80}
                          graphColor1={
                            key === "currentAmount"
                              ? updatedGraphData.every(
                                  (item) =>
                                    item.currentAmount >= item.investedAmount
                                )
                                ? "#20b2aa"
                                : "red"
                              : undefined
                          }
                          graphColor2={
                            key === "investedAmount"
                              ? "var(--mediumGrey)"
                              : undefined
                          }
                          returnColor={
                            key === "currentAmount" || key === "investedAmount"
                              ? undefined
                              : updatedGraphData.some(
                                  (item) => item.dailyReturn < 0
                                )
                              ? "red"
                              : "#20b2aa"
                          }
                          returnKey={
                            key === "currentAmount" || key === "investedAmount"
                              ? undefined
                              : "dailyReturn"
                          }
                          currentAmountKey={
                            key === "currentAmount"
                              ? "currentAmount"
                              : undefined
                          }
                          investedAmountKey={
                            key === "investedAmount"
                              ? "investedAmount"
                              : undefined
                          }
                        />
                      </LineCHart>
                    </AmountContainer>
                  );
                })}
              </Header>
            </div>
            <div style={{ flex: 2 }}>
              {loading ? (
                <LoadingMessage>
                  <MiniLoader contStyle={{ borderBottom: "none" }} />
                </LoadingMessage>
              ) : (
                <DashBoardLineChart
                  data={graphData}
                  fromMonthsBefore={selectedRange}
                  graphColor1="#20b2aa"
                  graphColor2="var(--mediumGrey)"
                  currentAmountKey="currentAmount"
                  investedAmountKey="investedAmount"
                  height={300}
                  useCustomTooltip={true}
                  useStrokeDasharray={true}
                />
              )}

              <TimeRangeButtons>
                {Object.keys(timeRanges).map((range) => (
                  <RangeButton
                    key={range}
                    onClick={() => setSelectedRange(range)}
                    isActive={selectedRange === range}
                  >
                    {range.replace("_", " ")}
                  </RangeButton>
                ))}
              </TimeRangeButtons>
            </div>
          </TopContainer>
        </LeftContainer>
        <RightContainer>
          <Heading style={{ marginTop: "1rem" }}>
            <p>Category Distribution</p>
            <StyledLine></StyledLine>
          </Heading>
          <BottomContainer>
            <div style={{ flex: 1 }}>
              <Allocator
                contStyle={{
                  margin: "0rem 0 0 0",
                  padding: "1rem",
                  borderRadius: "6px",
                  boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
                }}
                data={allocaterData}
                nameMapper="category"
                NumberMapper="sub_heading"
                valueMapper="percentage"
                viewType="table"
                graphType="circular"
                onClickOfRow={() => {
                  navigate("/portfolio/distribution", {
                    state: portfolioData,
                  });
                }}
              />{" "}
            </div>
            <div style={{ flex: 2 }}>
              <Distribution data={portfolioData} />
            </div>
          </BottomContainer>
        </RightContainer>
      </Container>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 980px) {
    margin-top: 7rem;
    flex-direction: column;
  }
`;

const Container = styled.div`
  /* display: flex; */
  gap: 4rem;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
const LeftContainer = styled.div`
  /* flex: 2; */
  margin-bottom: 4rem;
`;
const TopContainer = styled.div`
  display: flex;
  gap: 4rem;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  gap: 4rem;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HeadingContainer = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
`;
const Subheading = styled.p`
  font-size: 2rem;
  font-weight: 500;
`;

const Header = styled.div`
  display: grid;
  gap: 2rem;
  margin-bottom: 2rem;

  grid-template-columns: 1fr;

  @media (max-width: 980px) {
    grid-template-columns: 1fr 1fr;

    & > div:nth-child(1) {
      grid-column: span 2;
    }

    & > div:nth-child(2),
    & > div:nth-child(3) {
      width: 100%;
    }
  }
`;

const AmountContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 ${({ theme }) => theme.boxShadow};
  padding: 1rem;
  p {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #777;
  }

  h2 {
    font-size: 2rem;
    color: #333;
    font-weight: bold;
  }
`;
const LineCHart = styled.div`
  flex: 1;

  @media (max-width: 980px) {
    display: ${({ showGraph }) => (showGraph ? "block" : "none")};
  }
`;

const TimeRangeButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
  border-top: 1px solid #ccc;
  padding-top: 2rem;
`;

const RangeButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: ${({ isActive, theme }) =>
    isActive ? "#20b2ab2c" : theme.tagBackground};
  color: ${({ isActive }) => (isActive ? "var(--themeColor)" : "")};
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #20b2ab2c;
    color: var(--themeColor);
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const ErrorMessage = styled.p`
  font-size: 1.2rem;
  color: red;
  text-align: center;
`;

export default Dashboard;

export const Distribution = ({ data }) => {
  const location = useLocation();
  const state = data || location.state;
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    if (state && Array.isArray(state)) {
      const categoryFromState = state.find((category) => category.category);
      if (categoryFromState) {
        setSelectedCategory(categoryFromState.category);
      }
    }
  }, [state]);

  if (!state || !Array.isArray(state)) {
    return (
      <div>
        <>{JSON.stringify(state)}</>
      </div>
    );
  }

  const filteredData = selectedCategory
    ? state.filter((category) => category.category === selectedCategory)
    : state;

  const uniqueCategories = Array.from(
    new Set(state.map((category) => category.category))
  );

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div>
      <ButtonContainer>
        {uniqueCategories.map((category) => (
          <CategoryButton
            key={category}
            selected={selectedCategory === category}
            onClick={() => handleCategoryClick(category)}
            aria-pressed={selectedCategory === category}
          >
            {category}
          </CategoryButton>
        ))}
      </ButtonContainer>

      <DataContainer>
        {filteredData.map((category, index) => (
          <CategoryCard key={index}>
            <CategoryHeader>
              <CategoryTitle>
                {category.sub_heading} ({category.percentage}%)
              </CategoryTitle>

              <CategoryInfo>
                <HoldingCell className="hide-on-mobile">Units</HoldingCell>
                <HoldingCell className="current-amount">
                  Current Amount
                </HoldingCell>
                <HoldingCell className="hide-on-mobile">Returns</HoldingCell>
              </CategoryInfo>
            </CategoryHeader>
            {category.holdings.map((holding, idx) => (
              <HoldingRow key={idx}>
                <SchemeName>{holding.scheme_name}</SchemeName>
                <HoldingInfo>
                  <HoldingCell className="hide-on-mobile">
                    {holding.allocation.toFixed(2)}%
                  </HoldingCell>
                  <HoldingCell className="current-amount">
                    ₹{holding.current_amount}
                  </HoldingCell>
                  <HoldingCell className="hide-on-mobile">
                    ₹{holding.returns}
                  </HoldingCell>
                </HoldingInfo>
              </HoldingRow>
            ))}
          </CategoryCard>
        ))}
      </DataContainer>
    </div>
  );
};

const Heading = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
`;

const StyledLine = styled.p`
  margin: 5px 0;
  height: 7px;
  width: 40px;
  background-color: #20b2aa;
`;

const ButtonContainer = styled.div`
  margin-bottom: 2rem;
`;

const CategoryButton = styled.button`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: ${(props) =>
    props.selected ? "#20b2ab2c" : props.theme.tagBackground};
  color: ${(props) => (props.selected ? "var(--themeColor)" : "inherit")};
  border-radius: 50px;
  letter-spacing: 1px;
  cursor: pointer;
  border: 1px solid transparent;

  margin: 0 0.5rem;
  &:hover {
    background-color: #20b2ab2c;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
  }
  @media (max-width: 980px) {
    font-size: 1.2rem;
  }
`;

const DataContainer = styled.div``;

const CategoryCard = styled.div`
  border: 1px solid var(--secondaryLightGrey);
  border-radius: 8px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  border-bottom: 1px solid var(--secondaryLightGrey);
  font-weight: bold;

  padding: 2rem;
`;

const CategoryTitle = styled.div`
  flex: 1;
  font-size: 1.5rem;
  font-weight: bold;
  @media (max-width: 980px) {
    flex: 0.7;
  }
`;

const CategoryInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media (max-width: 980px) {
    .hide-on-mobile {
      display: none;
    }
    .current-amount {
      margin-left: auto;
    }
    width: 100%;
    flex: 0.3;
  }
`;

const HoldingRow = styled.div`
  display: flex;
  gap: 4rem;
  border-bottom: 1px solid #eee;
  padding: 2rem;
`;

const SchemeName = styled.div`
  flex: 1;
  @media (max-width: 980px) {
    flex: 0.7;
  }
`;

const HoldingInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media (max-width: 980px) {
    .hide-on-mobile {
      display: none;
    }
    .current-amount {
      margin-left: auto;
    }
    width: 100%;
    flex: 0.3;
  }
`;

const HoldingCell = styled.div`
  width: 32%;
  @media (max-width: 980px) {
    width: 100%;
  }
`;
