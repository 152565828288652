import Button from "components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import Note from "components/common/Note/Note";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CloseButton,
  StyledAlignCenter,
} from "styles/common-styled-components";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import ErrorMessage from "components/common/ErrorMessage/ErrorMessage";
import ModalPopup from "components/common/ModalPopup/ModalPopup";

const MandateAuthorizationPopup = ({ selectedBankForPayment }) => {
  const { userDetails } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [customMandateError, setCustomMandateError] = useState("");
  const [showAlert, setShowAlert] = useState(true);

  // Find the selected bank from userDetails.bankdetail
  const selectedBank = userDetails?.bankdetail?.find(
    (bank) => bank.id === selectedBankForPayment
  );

  // Check if the selected bank has mandates
  const mandates = selectedBank?.mandate || [];

  // Check for an approved mandate
  const approvedMandate = mandates.find(
    (curr) => curr.status_comment === "APPROVED"
  );

  // If no approved mandate, find the first matching one with specific statuses
  let pendingMandate;

  if (!approvedMandate) {
    pendingMandate = mandates.find(
      (curr) =>
        curr.status === "Registered in BSE" ||
        curr.status === "Received by BSE" ||
        curr.status === "Form submitted to BSE"
    );
  }

  // GET MANDATE LINK
  const handleCompleteMandate = (mandate_id) => {
    return credentialAxios.post(API_ENDPOINTS.getMandateLink, {
      mandate_id,
    });
    // return true;
  };

  const onCompleteMandateResSucceed = (res) => {
    if (res.data.html_link === "Please wait for sometime.") {
      setCustomMandateError("Please wait for sometime and try again.");
      return;
    }
    if (res.data.html_link) {
      setShowAlert(false);
      // if (true) {
      window.location.href = res.data.html_link;
      // window.location.replace("https://wisebulls.com/");
    }
  };

  const { mutate, isLoading } = useMutation(handleCompleteMandate, {
    onSuccess: onCompleteMandateResSucceed,
    onError: () => {},
  });

  const Modal = React.memo(() => {
    return (
      <ModalPopup
        show={showAlert}
        style={{ maxWidth: "300px", minWidth: "300px" }}
      >
        <div
          style={{
            padding: "2rem",
            border: "1px solid var(--secondaryLightGrey)",
            borderRadius: "4px",
          }}
        >
          <div style={{ marginBottom: "3rem" }}>
            <CloseButton
              onClick={() => {
                setShowAlert(false);
              }}
            />
          </div>

          <Note
            note="You don't have any approved mandates."
            contStyle={{ width: "auto" }}
          />

          <StyledAlignCenter>
            <Button
              contStyle={{ flex: 1 }}
              name={"AUTHORIZE MANDATE"}
              onClick={() => mutate(pendingMandate.id)}
              loading={isLoading}
              loadingText={"REDIRECTING"}
            />
          </StyledAlignCenter>
          <p
            style={{
              textDecoration: "underline",
              textAlign: "center",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/bank-details");
            }}
          >
            See all mandates
          </p>
          <ErrorMessage
            variant={"background"}
            show={customMandateError}
            message={customMandateError}
          />
        </div>
      </ModalPopup>
    );
  }, [showAlert]);

  if (pendingMandate) {
    return <Modal />;
  }

  return null;
};

export default MandateAuthorizationPopup;
