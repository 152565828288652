import React, { useState } from "react";
import { useQuery } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Tooltip,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { credentialAxios } from "utils/api-calls";
import { API_ENDPOINTS } from "utils/constants";
import MobileNav from "components/common/MobileNav/MobileNav";
import NoData from "pages/NoData/NoData";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  OrderCard,
  OrderStatus,
  OrdersCont,
  StatusText,
  OrdersHead,
  ChevronWithBg,
  MobileOrderCard,
  OrderContainer,
} from "./Orders.styles";
import { MobileOrderCardSkeleton, OrderCardSkeleton } from "./Orders.skeletons";
import { TagComp } from "pages/SingleMutualFund/SingleMutualFund.styles";
import {
  DesktopView,
  MobileView,
  StyledAlignCenter,
  StyledSpaceBetween,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";
import { capitalizeFundName } from "utils/functions";
import styled from "styled-components";
import OrderCategories from "./OrderCategories";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
const arr = new Array(10).fill(0);

export const MyOrdersPage = ({
  data,
  isLoading,
  navigateToDetailsPage,
  noDataMessage,
}) => {
  return (
    <>
      {/* MOBILE */}
      <MobileNav headerTitle={"Orders"} heightBelow="0" />

      {!isLoading && data.length === 0 ? (
        <NoData
          message={noDataMessage}
          subMessage={"Start exploring Mutual Funds"}
          redirecting="Explore Mutual Funds"
          onRedirect={() => Navigate("/mutual-funds")}
        />
      ) : (
        <>
          <DesktopView>
            {isLoading ? (
              <OrdersCont>
                {arr.map((_, i) => {
                  return <OrderCardSkeleton key={i} height={250} />;
                })}
              </OrdersCont>
            ) : (
              <OrderContainer>
                <OrdersHead>
                  <p>SCHEME NAME</p>
                  <p>AMOUNT</p>
                  <p>ORDER PLACED AT</p>
                  <p>ORDER STATUS</p>
                </OrdersHead>
                {data.map((order, index) => {
                  return (
                    <OrdersCont key={index}>
                      <div
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() =>
                          navigateToDetailsPage(order.order_number)
                        }
                      >
                        <OrderStatus
                          failed={order.status === "Cancelled/Failed"}
                        />
                        <OrderCard>
                          <div>
                            <p style={{ marginBottom: "1rem" }}>
                              {capitalizeFundName(order.scheme_name)}
                            </p>
                            {order.transaction_type === "Redemption" ? (
                              <TagComp
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "800",
                                  padding: "1rem 2rem",
                                }}
                              >
                                Redemption
                              </TagComp>
                            ) : (
                              <TagComp
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "800",
                                  padding: "1rem 2rem",
                                }}
                              >
                                {order.order_type === "1" ? "Lumpsum" : "SIP"}
                              </TagComp>
                            )}
                          </div>
                          <div>
                            <p
                              style={{ fontSize: "1.4rem", fontWeight: "bold" }}
                            >
                              ₹{Math.floor(order.amount)}
                            </p>
                          </div>
                          <p style={{ fontSize: "1.2rem" }}>
                            {order.transaction_type}, {order.type} placed at{" "}
                            {new Date(order.created).toString()}
                          </p>
                          <StyledSpaceBetweenAlignCenter
                            style={{ alignItems: "flex-start" }}
                          >
                            <StatusText
                              failed={order.status === "Cancelled/Failed"}
                              style={{
                                fontSize: "1.2rem",
                              }}
                            >
                              {order.status === "Cancelled/Failed"
                                ? "Cancelled/ Failed"
                                : order.status.toUpperCase()}
                            </StatusText>
                            {order.status === "Cancelled/Failed" && (
                              <Tooltip
                                title="Continue Order"
                                disableInteractive
                                style={{ fontSize: "2.4rem" }}
                              >
                                <ChevronWithBg />
                              </Tooltip>
                            )}
                          </StyledSpaceBetweenAlignCenter>
                        </OrderCard>
                      </div>
                    </OrdersCont>
                  );
                })}
              </OrderContainer>
            )}
          </DesktopView>
          <MobileView>
            {isLoading ? (
              <OrdersCont>
                {arr.map((_, i) => {
                  return <MobileOrderCardSkeleton key={i} height={250} />;
                })}
              </OrdersCont>
            ) : (
              <>
                <OrdersCont>
                  {data.map((order, index) => {
                    return (
                      <div
                        key={index}
                        style={{ display: "grid", cursor: "pointer" }}
                        onClick={() =>
                          navigateToDetailsPage(order.order_number)
                        }
                      >
                        <OrderStatus
                          failed={order.status === "Cancelled/Failed"}
                        />
                        <MobileOrderCard>
                          <StyledSpaceBetween
                            style={{ marginBottom: "1.4rem" }}
                          >
                            <div style={{ flex: 0.7 }}>
                              <p
                                style={{
                                  marginBottom: "1rem",
                                  fontSize: "1.2rem",
                                }}
                              >
                                {order.scheme_name}
                              </p>
                              {order.transaction_type ===
                              "Redemption" ? null : (
                                <TagComp
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "800",
                                    padding: "1rem 2rem",
                                  }}
                                >
                                  {order.order_type === "1" ? "Lumpsum" : "SIP"}
                                </TagComp>
                              )}
                              <StyledAlignCenter>
                                <StatusText
                                  failed={order.status === "Cancelled/Failed"}
                                  style={{ fontSize: "1.2rem" }}
                                >
                                  {order.status.toUpperCase()}
                                </StatusText>
                              </StyledAlignCenter>
                            </div>
                            <div style={{ flex: 0.3 }}>
                              <p
                                style={{
                                  fontSize: "1.4rem",
                                  fontWeight: "bold",
                                  textAlign: "right",
                                }}
                              >
                                ₹{order.amount}
                              </p>
                              <p
                                style={{
                                  fontSize: "1.2rem",
                                  textAlign: "right",
                                }}
                              >
                                {new Date(order.created).toLocaleDateString(
                                  "en-IN",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                          </StyledSpaceBetween>
                        </MobileOrderCard>
                      </div>
                    );
                  })}
                </OrdersCont>
              </>
            )}
          </MobileView>
        </>
      )}
    </>
  );
};

const Orders = () => {
  useDocumentTitle("Your Orders - WiseBulls");
  const [showOrders, setShowOrders] = useState(true);
  const [transactionType, setTransactionType] = useState("All");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 980px)");
  const navigate = useNavigate();

  const getOrdersDetail = () => {
    return credentialAxios.get(API_ENDPOINTS.getOrdersDetails);
  };

  const { data, isLoading, error } = useQuery("orders", getOrdersDetail);

  const toggleView = () => {
    setShowOrders((prevState) => !prevState);
  };

  const toggleFilterDrawer = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const filteredData =
    transactionType === "All"
      ? data?.data || []
      : (data?.data || []).filter(
          (order) => order.transaction_type === transactionType
        );

  return (
    <MainContainer>
      {isMobile ? (
        <>
          <Drawer
            anchor="left"
            open={isFilterOpen}
            onClose={toggleFilterDrawer}
            sx={{
              "& .MuiDrawer-paper": {
                width: "50%",
                padding: "1rem",
                marginTop: "6rem",
              },
            }}
          >
            <Typography variant="h5" gutterBottom>
              Transaction Type
            </Typography>
            <List
              sx={{
                marginLeft: "-1rem",
              }}
            >
              {["All", "Purchase", "Redemption"].map((option) => (
                <ListItem
                  key={option}
                  button
                  onClick={() => {
                    setTransactionType(option);
                    toggleFilterDrawer();
                  }}
                >
                  <Checkbox
                    checked={transactionType === option}
                    sx={{
                      transform: "scale(1.5)",
                    }}
                  />
                  <ListItemText
                    primary={option}
                    primaryTypographyProps={{
                      fontSize: "1.2rem",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </>
      ) : (
        <FilterContainer>
          <Title>Transaction Type</Title>
          <FilterOptions>
            {["All", "Purchase", "Redemption"].map((option) => (
              <FilterOption key={option}>
                <label>
                  <input
                    type="checkbox"
                    checked={transactionType === option}
                    onChange={() => setTransactionType(option)}
                  />
                  {option}
                </label>
              </FilterOption>
            ))}
          </FilterOptions>
        </FilterContainer>
      )}

      <Container>
        <HeaderContainer>
          <Icon>
            <IconButton
              onClick={toggleFilterDrawer}
              sx={{
                marginBottom: "0rem",
                alignSelf: "center",
              }}
            >
              <FilterListIcon />
            </IconButton>
          </Icon>
          <Header>
            <ToggleButton onClick={toggleView} isActive={!showOrders}>
              {showOrders ? (
                "Group Orders by AMC's"
              ) : (
                <>
                  Group Orders by AMC's <CloseIcon />
                </>
              )}
            </ToggleButton>
          </Header>
        </HeaderContainer>
        <Content>
          {error || (filteredData.length === 0 && transactionType === "All") ? (
            <NoData
              message="You have no orders yet"
              subMessage="Start exploring Mutual Funds"
              redirecting="Explore Mutual Funds"
              onRedirect={() => navigate("/mutual-funds")}
            />
          ) : filteredData.length === 0 ? (
            <NoData
              message={
                transactionType === "Redemption"
                  ? "You have no redemptions yet."
                  : "You have no purchases yet."
              }
              showButton={false}
            />
          ) : showOrders ? (
            <MyOrdersPage
              data={filteredData}
              isLoading={isLoading}
              navigateToDetailsPage={(orderNumber) =>
                navigate(`/orders/${orderNumber}`)
              }
              noDataMessage="You have no orders yet."
            />
          ) : (
            <OrderCategories transactionType={transactionType} />
          )}
        </Content>
      </Container>
    </MainContainer>
  );
};

export default Orders;

// Styled Components
const MainContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 4rem;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 980px) {
    margin-top: 6rem;
    flex-direction: column;
  }
`;
const HeaderContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;
const Icon = styled("div")`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.6rem;
  @media (min-width: 980px) {
    display: none;
  }
`;
const FilterContainer = styled("div")`
  position: sticky;
  top: 14rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  flex-grow: 0.6;
  height: auto;
  width: auto;
  min-height: 0;
  box-sizing: border-box;
`;

const Title = styled("h3")`
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  padding: 1rem;
`;

const FilterOptions = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FilterOption = styled("div")`
  padding: 1rem;
  label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.4rem;
  }

  input[type="checkbox"] {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

const Container = styled("div")`
  margin-top: 0;
  flex: 4;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const Header = styled("div")`
  display: flex;
  justify-content: flex-start;
  /* margin-bottom: 1rem; */
`;

const ToggleButton = styled("div")`
  padding: 1.2rem 1.4rem;
  background-color: ${(props) =>
    props.isActive ? "var(--themeColor)" : "transparent"};
  color: ${(props) => (props.isActive ? "#fff" : "inherit")};
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${(props) =>
      props.isActive ? "var(--themeColor)" : "var(--light-grey)"};
    /* color: #fff; */
  }

  .cross {
    display: ${(props) => (props.isActive ? "inline-block" : "none")};
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Content = styled("div")`
  margin-top: 1rem;
`;
