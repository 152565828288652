import React, { useState } from "react";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useSelector } from "react-redux";

import Button from "components/ui/Button/Button";
import Select from "components/ui/Select/Select";
import { mapServerErrorsToLocal } from "utils/functions";
import { API_ENDPOINTS } from "utils/constants";
import { credentialAxios } from "utils/api-calls";

import { Form2 } from "styles/common-styled-components";
import InputField from "components/ui/InputField/InputField";
import CheckboxComp from "components/ui/CheckboxComp/CheckboxComp";

const AddBankForm = ({ ifsc_code, editBankId }) => {
  const location = useLocation();
  const { userDetails } = useSelector((state) => state.user);

  const initialErrorState = {
    account_number: "",
    confirm_account_number: "",
    account_type: "",
    general: "",
  };

  const fromLocation = location.state?.fromLocation
    ? location.state.fromLocation
    : "/registration/add-nominee";

  const queryParams = new URLSearchParams(location.search);

  const editModeParam = queryParams.get("edit_mode");

  const editMode = editModeParam === "true";

  const navigate = useNavigate();

  const [errorState, setErrorState] = useState(initialErrorState);

  const validationSchema = Yup.object().shape({
    account_number: Yup.string().required("Account Number is required"),
    confirm_account_number: Yup.string()
      .oneOf([Yup.ref("account_number"), null], "Account numbers must match")
      .required("Confirm Account Number is required"),
    account_type: Yup.string().required("Account Type is required"),
  });

  //// API CALL

  const handleAddBank = (data) => {
    const { confirm_account_number, ...requestData } = data;

    if (editBankId) {
      return credentialAxios.put(API_ENDPOINTS.addBank, {
        ...requestData,
        id: editBankId,
        ifsc: ifsc_code,
      });
    } else {
      return credentialAxios.post(API_ENDPOINTS.addBank, {
        ...requestData,
        ifsc: ifsc_code,
      });
    }
  };

  const onSuccess = (res) => {
    setErrorState(initialErrorState);
    navigate(fromLocation);
  };

  const onError = (err) => {
    const newErrors = mapServerErrorsToLocal(err, initialErrorState, [
      "account_number",
      "account_type",
    ]);
    setErrorState(newErrors);
  };

  const { mutate, isLoading } = useMutation(handleAddBank, {
    onSuccess,
    onError,
  });

  return (
    <Formik
      initialValues={{
        account_number: "",
        account_type: "",
        default:
          userDetails.bankdetail &&
          userDetails.bankdetail.length > 0 &&
          !editMode
            ? false
            : true,
      }}
      onSubmit={mutate}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => {
        return (
          <Form2 onSubmit={handleSubmit}>
            <InputField
              label={"Account Number"}
              value={values.account_number}
              onChange={handleChange("account_number")}
              autoFocus
              passwordField
              noSpin
              error={
                (touched.account_number && errors.account_number) ||
                errorState.account_number
              }
              autoComplete="new-password"
            />
            <InputField
              label={"Confirm Account Number"}
              value={values.confirm_account_number}
              onChange={handleChange("confirm_account_number")}
              error={
                (touched.confirm_account_number &&
                  errors.confirm_account_number) ||
                errorState.confirm_account_number
              }
            />
            <Select
              options={[
                { label: "Savings Account", value: "SB" },
                { label: "Current Account", value: "CB" },
              ]}
              label={"Account Type"}
              value={values.account_type}
              onChange={handleChange("account_type")}
              error={
                touched.account_type &&
                (errors.account_type || errorState.account_type)
              }
            />
            {!editMode &&
            userDetails.bankdetail &&
            userDetails.bankdetail.length > 0 ? (
              <CheckboxComp
                label={"Make this Bank your default"}
                onChange={(bool) => setFieldValue("default", bool)}
                checked={values.default}
              />
            ) : null}

            <Button
              title={editMode ? "EDIT AND SAVE" : "SUBMIT"}
              type={"submit"}
              isLoading={isLoading}
              error={errorState.general}
            />
          </Form2>
        );
      }}
    </Formik>
  );
};

export default AddBankForm;
