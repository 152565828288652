import React, { useEffect, useState } from "react";
import {
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { credentialAxios } from "utils/api-calls";
import { capitalizeFundName } from "utils/functions";

const CurrentSip = () => {
  const [sortOption, setSortOption] = useState("A-Z");
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleNavigation = (sip_regn_number) => {
    navigate(`/active-sips/${sip_regn_number}`);
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortOption === "A-Z") return a.scheme_name.localeCompare(b.scheme_name);
    if (sortOption === "Due Date")
      return new Date(a.due_date) - new Date(b.due_date);
    return 0;
  });

  const totalSIPAmount = data.reduce(
    (sum, fund) => sum + parseFloat(fund.amount || 0),
    0
  );

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const fetchSipData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await credentialAxios.get("/api/active-sips/");
      setData(response.data.sip_details_list || []);
    } catch (err) {
      setError("Failed to fetch SIP data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getNearestAmount = () => {
    const today = new Date();
    const upcomingInstallment = data
      .filter((item) => new Date(item.due_date) >= today)
      .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))[0];
    return upcomingInstallment ? upcomingInstallment.amount : "0.00";
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return {
      day: date.getDate(),
      month: date.toLocaleString("en-US", { month: "short" }),
    };
  };

  useEffect(() => {
    fetchSipData();
  }, []);

  return (
    <MainContainer>
      <div style={{ flex: "0.4" }}>
        <Typography variant="h4" fontWeight="bold">
          My SIPs
        </Typography>
        <div style={styles.highlightBar} />
        <p style={{ marginTop: "1rem" }}>View and manage your active SIPs</p>
      </div>
      <div style={styles.container}>
        <div style={styles.monthlyAmountBox}>
          <Typography variant="h5" style={styles.sipAmountText}>
            ₹{totalSIPAmount.toFixed(2)}
          </Typography>
          <p>Monthly SIP amount</p>
        </div>

        <div style={styles.headerBox}>
          <p style={{ fontWeight: "500" }}>Active SIPs ({data.length})</p>
          <div style={styles.sortButton} onClick={handleMenuClick}>
            <SortIcon />
            &nbsp; <p>Sort By</p>
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {["A-Z", "Due Date"].map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  setSortOption(option);
                  handleMenuClose();
                }}
              >
                {sortOption === option ? (
                  <RadioButtonCheckedIcon style={{ marginRight: "1rem" }} />
                ) : (
                  <RadioButtonUncheckedIcon style={{ marginRight: "1rem" }} />
                )}
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <div style={styles.installmentReminder}>
          <CalendarTodayIcon />
          <p>
            Maintain{" "}
            <span style={{ fontWeight: "bold" }}>₹{getNearestAmount()}</span> in
            your bank for upcoming installment
          </p>
        </div>

        {loading ? (
          <div style={styles.loadingContainer}>
            <CircularProgress />
            <Typography>Loading...</Typography>
          </div>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <List>
            {sortedData.map((item) => (
              <ListItemContainer key={item.sip_regn_number}>
                <div
                  onClick={() => handleNavigation(item.sip_regn_number)}
                  style={{
                    cursor: "pointer",
                    padding: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "1.6rem",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      ...styles.logo,
                      borderRadius: "4px",
                      // marginRight: "1rem",
                      backgroundColor: item.logo_url
                        ? "transparent"
                        : "#ae6e57",
                    }}
                  >
                    {item.logo_url ? (
                      <img
                        src={item.logo_url}
                        alt={item.scheme_name || "Logo"}
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <p>{item.scheme_name?.charAt(0)}</p>
                    )}
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <p style={{ marginBottom: "4px" }}>
                      {capitalizeFundName(item.scheme_name)}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <p style={{ fontWeight: "500", fontSize: "1.2rem" }}>
                        ₹{item.amount}
                      </p>

                      <div
                        style={{
                          width: "4px",
                          height: "4px",
                          borderRadius: "50%",
                          backgroundColor: "#e0e0e0",
                        }}
                      ></div>
                      <Datetext
                        style={{ fontWeight: "500", fontSize: "1.2rem" }}
                      >
                        {formatDate(item.due_date).day}{" "}
                        {formatDate(item.due_date).month}
                      </Datetext>
                    </div>
                  </div>
                  <DueDateBox>
                    <p style={{ fontSize: "1.3rem" }}>
                      {formatDate(item.due_date).day}
                      <br />
                      {formatDate(item.due_date).month}
                    </p>
                  </DueDateBox>
                </div>
              </ListItemContainer>
            ))}
          </List>
        )}
      </div>
    </MainContainer>
  );
};

export default CurrentSip;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: 400px) {
    flex-direction: column;
    margin-top: 6rem;
  }
`;

export const ListItemContainer = styled.div`
  margin-bottom: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;

  @media (max-width: 980px) {
    padding: 8;
  }
`;
export const DueDateBox = styled.div`
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px;
  min-width: 60px;
  @media (max-width: 980px) {
    display: none;
  }
`;
export const Datetext = styled.p`
  @media (min-width: 980px) {
    display: none;
  }
`;

const styles = {
  container: {
    flex: "0.6",
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "8px",
  },
  highlightBar: {
    width: "40px",
    height: "5px",
    backgroundColor: "#20b2aa",
    marginTop: "8px",
  },
  monthlyAmountBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    borderBottom: "1px solid #e0e0e0",
  },
  sipAmountText: {
    fontWeight: "bold",
    fontSize: "25px",
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },
  sortButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  installmentReminder: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px",
    backgroundColor: "#e5e5e5",
    borderRadius: "8px",
    marginTop: "16px",
    marginBottom: "16px",
  },
  logo: {
    height: "4.8rem",
    width: "4.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: " 1px solid #e0e0e0",
    borderRadius: "8px",
    overflow: "hidden",
    // marginRight: "16px",
    padding: "6px",
    flexShrink: 0,
  },

  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
  },
};
