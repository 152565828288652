import React, { useState, useEffect } from "react";
import ModalPopup from "../ModalPopup/ModalPopup";
import Button from "../Button/Button";

const SessionExpiredDialog = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Check session expiration flag in sessionStorage on component mount
  useEffect(() => {
    const sessionExpired = sessionStorage.getItem("sessionExpired");
    if (sessionExpired === "true") {
      setIsVisible(true);
      sessionStorage.removeItem("sessionExpired"); // Clear the flag
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <ModalPopup show={isVisible}>
      <div style={{ padding: "2rem" }}>
        <p
          style={{
            fontWeight: "600",
            fontSize: "1.6rem",
            marginBottom: "1.2rem",
            textAlign: "center",
          }}
        >
          Your session has expired. Please log in again.
        </p>
        <Button onClick={handleClose} title={"OK"} />
      </div>
    </ModalPopup>
  );
};

export default SessionExpiredDialog;
