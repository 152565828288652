import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AlertContext } from "App";
import {
  CloseButton,
  StyledSpaceBetweenAlignCenter,
} from "styles/common-styled-components";

import { CurrencyField } from "components/common/InputField/InputField";
import { getDateSuffix } from "utils/functions";
import Button from "components/common/Button/Button";
import { Label } from "components/common/InputField/InputField.styles";
import LoginModalContent from "components/common/LoginRegisterContent/LoginModalContent";
import Tab from "components/common/Tab/Tab";
import {
  Container,
  DateButton,
  DateButtonTxt,
  DateCompCont,
  DateSelectorComp,
  FormsCont,
  FormTabsCont,
  SelectedSIPDate,
} from "./InvestOptions.styles";
import {
  CompleteSetupCard,
  PendingRegistrationCard,
} from "pages/Explore/Explore";

const INVESTMENT_OPTIONS = ["Monthly SIP", "Lumpsum"];

const InvestOptions = ({
  data,
  noSip = false,
  editState = null,
  ref,
  fromLocation = "/",
  additionalPurchase,
  folio_number,
  noSipMessage = "Monthly SIP is not available in this scheme",
}) => {
  const navigate = useNavigate();

  const { openModal, closeModal } = useContext(AlertContext);

  const { currentUser, nextRegistrationScreen, userDetails } = useSelector(
    (state) => state.user
  );

  const defaultInvestmentTypeSelected =
    editState !== null && editState.investmentType !== "sip"
      ? INVESTMENT_OPTIONS[1]
      : noSip
      ? INVESTMENT_OPTIONS[1]
      : INVESTMENT_OPTIONS[0];

  const [selectedInvestment, setSelectedInvestment] = useState(
    defaultInvestmentTypeSelected
  );

  const handleInvestmentSubmit = (e, obj) => {
    e.preventDefault();
    if (!obj.amount) {
      return;
    }
    if (userDetails.has_user_submitted_kyc) {
      openModal({
        comp: (
          <PendingRegistrationCard
            showClose
            style={{ boxShadow: "none", width: "100%" }}
            navigate={() => {
              closeModal();
              navigate(nextRegistrationScreen);
            }}
            closeModal={closeModal}
          />
        ),
        style: {
          maxHeight: "75vh",
          width: "30rem",
          minWidth: "30rem",
        },
      });
      return;
    }
    if (!currentUser) {
      openModal({
        comp: (
          <LoginModalContent
            closeModal={closeModal}
            fromLocation={fromLocation}
          />
        ),
        style: { maxHeight: "75vh" },
      });
      return;
    }
    if (nextRegistrationScreen) {
      openModal({
        comp: (
          <CompleteSetupCard
            showClose
            style={{ boxShadow: "none", width: "100%" }}
            navigate={() => {
              closeModal();
              navigate(nextRegistrationScreen);
            }}
            closeModal={closeModal}
          />
        ),
        style: {
          maxHeight: "75vh",
          width: "30rem",
          minWidth: "30rem",
        },
      });
      return;
    }

    const { bse_code, id, name } = data;
    navigate(`/mutual-funds/${data.id}/payment`, {
      state: {
        ...obj,
        bse_code,
        id,
        name,
        folio_number,
      },
    });
  };
  return (
    <Container id="invest-options" ref={ref}>
      <FormTabsCont>
        <Tab
          options={INVESTMENT_OPTIONS}
          selectedOption={selectedInvestment}
          onSelect={(value) => setSelectedInvestment(value)}
        />
      </FormTabsCont>
      <FormsCont>
        {selectedInvestment === INVESTMENT_OPTIONS[0] ? (
          data.if_sip ? (
            <SipForm
              data={data}
              handleInvestmentSubmit={handleInvestmentSubmit}
              noSip={noSip}
              noSipMessage={noSipMessage}
              additionalPurchase={additionalPurchase}
              editState={
                editState !== null && editState.investmentType === "sip"
                  ? editState
                  : null
              }
            />
          ) : (
            <>Restricted by fund house</>
          )
        ) : data.purchase_allowed ? (
          <LumpsumForm
            additionalPurchase={additionalPurchase}
            data={data}
            handleInvestmentSubmit={handleInvestmentSubmit}
            editState={
              editState !== null && editState.investmentType !== "sip"
                ? editState
                : null
            }
          />
        ) : (
          <>Restricted by fund house</>
        )}
      </FormsCont>
    </Container>
  );
};

const SipForm = ({
  data,
  handleInvestmentSubmit,
  noSip = false,
  editState = null,
  additionalPurchase,
  noSipMessage,
}) => {
  const montly_frequency = noSip
    ? null
    : data.sip_schemes.filter((curr) => curr.sip_frequency === "MONTHLY")[0];

  const dates = noSip ? null : montly_frequency.sip_start_dates.split(",");

  const minAmount =
    data.sip_schemes.length === 0 ? null : montly_frequency?.min_sip_inst;

  const maxAmount =
    data.sip_schemes.length === 0 ? null : montly_frequency?.max_sip_inst;
  const [amount, setAmount] = useState(
    editState !== null && editState.amount ? editState.amount : minAmount
  );

  // FOR DATE SELECTION

  const [showDateSelector, setShowDateSelector] = useState(false);

  const [confirmDate, setConfirmDate] = useState(
    editState !== null && editState.dateValue ? editState.dateValue : 1
  );
  const [confirmDateSuffix, setConfirmDateSuffix] = useState(
    editState !== null && editState.dateValue
      ? getDateSuffix(editState.dateValue)
      : "st"
  );

  return noSip ? (
    <div style={{}}>
      <p style={{ textAlign: "center" }}>{noSipMessage}</p>
    </div>
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!amount || Number(amount) < minAmount) return;

        handleInvestmentSubmit(e, {
          amount,
          investmentType: "sip",
          monthlySipDate: `${confirmDate}${confirmDateSuffix}`,
        });
      }}
    >
      <CurrencyField
        label={"SIP Amount"}
        labelStyle={{ fontSize: "1.4rem" }}
        defaultValue={amount}
        type="currency"
        placeholder="₹"
        onValueChange={(value) => {
          setAmount(value);
        }}
        error={
          !amount || Number(amount) < minAmount
            ? `Minimum SIP amount is ${minAmount}`
            : "" || Number(amount) > maxAmount
            ? `Maximum SIP amount is ${maxAmount}`
            : ""
        }
      />

      <StyledSpaceBetweenAlignCenter style={{ marginTop: "2rem" }}>
        <Label style={{ fontSize: "1.4rem" }}>Monthly SIP Date</Label>
        <SelectedSIPDate onClick={() => setShowDateSelector(true)}>
          {confirmDate}
          {confirmDateSuffix}
        </SelectedSIPDate>
        {showDateSelector ? (
          <DateSelectorComponent
            closeModal={() => setShowDateSelector(false)}
            editState={editState}
            dates={dates}
            onConfirm={(d, suffix) => {
              setConfirmDate(d);
              setConfirmDateSuffix(suffix);
            }}
          />
        ) : null}
      </StyledSpaceBetweenAlignCenter>

      <Button
        type={"submit"}
        style={{ width: "100%", marginTop: "2rem" }}
        name={additionalPurchase ? "INVEST MORE" : "INVEST"}
      />
    </form>
  );
};

const LumpsumForm = ({
  data,
  handleInvestmentSubmit,
  editState = null,
  additionalPurchase,
}) => {
  const minAmount = data.min_inv;

  const [amount, setAmount] = useState(
    editState !== null && editState.amount ? editState.amount : minAmount
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!amount || Number(amount) < minAmount) return;

        handleInvestmentSubmit(e, {
          amount,
          investmentType: "lumpsum",
        });
      }}
    >
      <CurrencyField
        label={"Lumpsum Amount"}
        labelStyle={{ fontSize: "1.4rem" }}
        type="currency"
        defaultValue={amount}
        placeholder="₹"
        onValueChange={(value) => {
          setAmount(value);
        }}
        error={
          !amount || Number(amount) < minAmount
            ? `Minimum Lumpsum amount is ${minAmount}`
            : ""
        }
      />

      <Button
        type={"submit"}
        style={{ width: "100%", marginTop: "2rem" }}
        name={additionalPurchase ? "INVEST MORE" : "INVEST"}
      />
    </form>
  );
};

export const DateSelectorComponent = ({
  closeModal,
  dates,
  editState,
  onConfirm,
  style,
}) => {
  const arrayForDate = new Array(31).fill(0);
  const [selectedDate, setSelectedDate] = useState(
    editState !== null && editState?.dateValue ? editState?.dateValue : 1
  );

  const dateConfirmSuffix = (date) => {
    if (date === 1 || date === 21 || date === 31) {
      return "st";
    } else if (date === 2 || date === 22) {
      return "nd";
    } else if (date === 3 || date === 23) {
      return "rd";
    } else {
      return "th";
    }
  };

  return (
    <DateSelectorComp style={style}>
      <DateCompCont style={{}}>
        <CloseButton onClick={closeModal} />

        <Label style={{ textAlign: "center" }}>SIP Installement Date</Label>
        <div style={{ padding: "4px" }}>
          <div
            style={{
              display: "flex",
              padding: "1.6rem 0",
              flexWrap: "wrap",
              fontSize: "1.2rem",
            }}
          >
            {arrayForDate.map((_, i) => {
              return !dates.includes(`${i + 1}`) ? (
                <div
                  style={{
                    width: "14.2%",
                    margin: "8px 0",
                  }}
                  key={i}
                >
                  <DateButton active={selectedDate === i + 1}>
                    <p
                      style={{
                        color: "#aaa",
                      }}
                    >
                      {i + 1}
                    </p>
                  </DateButton>
                </div>
              ) : (
                <div
                  style={{
                    width: "14.2%",
                    margin: "8px 0",
                    cursor: "pointer",
                    fontSize: "1.2rem",
                  }}
                  onClick={() => {
                    setSelectedDate(i + 1);
                  }}
                >
                  <DateButton active={selectedDate === i + 1}>
                    <DateButtonTxt active={selectedDate === i + 1}>
                      {i + 1}
                    </DateButtonTxt>
                  </DateButton>
                </div>
              );
            })}
          </div>
        </div>

        <Button
          name={"CONFIRM"}
          onClick={() => {
            onConfirm(selectedDate, dateConfirmSuffix(selectedDate));
            closeModal();
          }}
          style={{ width: "100%", marginTop: "2rem" }}
        />
      </DateCompCont>
    </DateSelectorComp>
  );
};

export default InvestOptions;
