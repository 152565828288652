import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { centering } from "styles/common-styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PieChart, Pie, Cell } from "recharts";

const _Allocater = ({
  data,
  nameMapper,
  valueMapper,
  NumberMapper,
  contStyle,
  viewType,
  graphType,
  onClickOfRow = () => {},
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const COLORS = [
    "var(--pumpkin)",
    "var(--delft-blue)",
    "var(--cardinal)",
    "var(--electric-indigo)",
    "var(--tufts-blue)",
    "var(--icterine)",
    "var(--atomic-tangerine)",
    "var(--red-crayola)",
    "var(--brown-sugar)",
    "var(--saffron)",
    "var(--midnight-green)",
    "var(--skobelof)",
    "var(--dun)",
    "var(--falu-red)",
    "var(--antiflash-white)",
  ];

  const sortedData = [...data].sort((a, b) => b[valueMapper] - a[valueMapper]);
  const chartData = data.map((item) => ({
    name: item[nameMapper],
    value: item[valueMapper],
  }));

  return (
    <div style={{ margin: "4rem 0", ...contStyle }}>
      {viewType === "labels" && (
        <LabelsCont>
          {sortedData.map((curr, i) => (
            <LabelCont
              key={`label-${i}`}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                opacity: hoveredIndex !== null && hoveredIndex !== i ? 0.8 : 1,
              }}
            >
              <div
                style={{
                  backgroundColor: COLORS[i],
                  width: "12px",
                  height: "12px",
                  borderRadius: "6px",
                }}
              />
              {curr[nameMapper]} ({Number(curr[valueMapper]).toFixed(2)}%)
            </LabelCont>
          ))}
        </LabelsCont>
      )}

      {graphType === "bar" && (
        <Bar>
          {sortedData.map((curr, i) => (
            <SubCont
              key={`sub-${i}`}
              style={{
                backgroundColor: COLORS[i],
                width: `${curr[valueMapper]}%`,
                opacity: hoveredIndex !== null && hoveredIndex !== i ? 0.5 : 1,
              }}
              onMouseEnter={() => setHoveredIndex(i)}
              onMouseLeave={() => setHoveredIndex(null)}
            ></SubCont>
          ))}
        </Bar>
      )}

      {graphType === "circular" && (
        <PieChartContainer>
          <PieChart width={280} height={280}>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              onMouseEnter={(_, index) => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              style={{
                cursor: "pointer",
              }}
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  opacity={
                    hoveredIndex !== null && hoveredIndex !== index ? 0.5 : 1
                  }
                />
              ))}
            </Pie>
            {hoveredIndex !== null && (
              <text
                x="50%"
                y="50%"
                dy={8}
                textAnchor="middle"
                style={{
                  fontSize: "14px",

                  fill: "var(--themeDarkGrey)",
                }}
              >
                {`${chartData[hoveredIndex].name}: ${chartData[hoveredIndex].value}%`}
              </text>
            )}
          </PieChart>
        </PieChartContainer>
      )}

      {viewType === "table" && (
        <TableCont>
          <table>
            <tbody>
              {sortedData.map((curr, i) => (
                <TableRow
                  onClick={() => {
                    onClickOfRow();
                  }}
                  key={`table-row-${i}`}
                  onMouseEnter={() => setHoveredIndex(i)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    opacity:
                      hoveredIndex !== null && hoveredIndex !== i ? 0.8 : 1,
                    cursor: "pointer",
                  }}
                >
                  <RowLeft>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <ColorBox style={{ backgroundColor: COLORS[i] }} />

                      <span>{curr[nameMapper]}</span>
                    </div>
                    <div>
                      <span>{curr[NumberMapper]}</span>
                    </div>
                  </RowLeft>

                  <RowRight>
                    <span>{Number(curr[valueMapper]).toFixed(2)}%</span>
                    {/* <ChevronRightIcon /> */}
                  </RowRight>
                </TableRow>
              ))}
            </tbody>
          </table>
        </TableCont>
      )}
    </div>
  );
};

_Allocater.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      nameMapper: PropTypes.string,
      valueMapper: PropTypes.number,
      NumberMapper: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  nameMapper: PropTypes.string.isRequired,
  valueMapper: PropTypes.string.isRequired,
  NumberMapper: PropTypes.string.isRequired,
  contStyle: PropTypes.object,
  viewType: PropTypes.oneOf(["labels", "table", "bar", "circular"]).isRequired,
  graphType: PropTypes.oneOf(["bar", "circular"]),
};

export default _Allocater;

const Bar = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
`;

const SubCont = styled.div`
  height: 100%;
  color: white;
  ${centering}
  transition: opacity 0.3s ease;
`;

const PieChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 2rem 0; */
`;

const LabelsCont = styled.div`
  ${centering}
  gap: 28px;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const LabelCont = styled.div`
  ${centering}
  gap: 4px;
`;

const TableCont = styled.div`
  width: 100%;
  margin-bottom: 10px;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tbody tr:hover {
    background-color: var(--light-gray);
  }
`;

const TableRow = styled.tr`
  display: flex;
  align-items: center;
  padding: 1rem 0.4rem;
  border-bottom: 1px solid var(--secondaryLightGrey);
  transition: all 0.3s ease-in-out;

  :hover {
    background-color: var(--lightGrey) !important;
  }
`;

const RowLeft = styled.div`
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  @media (max-width: 980px) {
    flex: 0.4;
  }
`;

const RowRight = styled.div`
  flex: 0.6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  @media (max-width: 980px) {
    flex: 0.6;
  }
`;

const ColorBox = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
`;
