import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";

const SipStepIndicator = ({
  sipJourney = [],
  sipDetails = {},
  sip_regn_number,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleClick = (index) => {
    navigate(`/active-sips/${sip_regn_number}/allotment`, {
      state: { sipDetails, index },
    });
  };

  const processingStep = sipJourney.find((step) => step.is_upcoming_instalment);
  const completedSteps = sipJourney
    .filter((step) => !step.is_upcoming_instalment)
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const allSteps = processingStep
    ? [processingStep, ...completedSteps]
    : completedSteps;

  const stepsToDisplay =
    isExpanded || allSteps.length <= 2 ? allSteps : allSteps.slice(0, 2);

  return (
    <div style={styles.stepIndicator}>
      {stepsToDisplay.map((step, index) => (
        <React.Fragment key={index}>
          <div style={styles.stepContainer}>
            <div>
              {step.instalment_status === "Completed" ? (
                <CheckCircleTwoToneIcon style={styles.iconLarge} />
              ) : (
                <CalendarTodayIcon style={styles.iconSmall} />
              )}
            </div>
            <div style={styles.stepContent}>
              <div
                style={{
                  ...styles.stepLabel,
                  cursor: step.is_upcoming_instalment ? "none" : "pointer",
                  textDecoration: step.is_upcoming_instalment
                    ? "inherit"
                    : "underline",
                }}
                onClick={() => {
                  if (step.is_upcoming_instalment) {
                    return;
                  }
                  handleClick(index);
                }}
              >
                {step.title || "Step Title Missing"}
              </div>
              <div style={styles.stepValue}>
                {new Date(step.date).toLocaleDateString()}
              </div>
            </div>
          </div>

          {index < stepsToDisplay.length - 1 && (
            <div style={styles.connector}></div>
          )}
        </React.Fragment>
      ))}

      {allSteps.length > 2 && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={styles.viewMore}
        >
          {isExpanded ? "View Less" : "View More"}
        </button>
      )}
    </div>
  );
};

export default SipStepIndicator;

const styles = {
  stepIndicator: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  stepContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  iconLarge: {
    fontSize: 20,
    color: "#20b2aa",
    marginRight: 16,
  },
  iconSmall: {
    fontSize: 20,
    color: "#20b2aa",
    marginRight: 16,
  },
  stepContent: {
    display: "flex",
    flexDirection: "column",
  },
  stepLabel: {
    color: "#555",
    fontWeight: "bold",
  },
  stepValue: {
    fontSize: 13,
    color: "#888",
  },
  connector: {
    width: 1,
    height: 40,
    backgroundColor: "#20b2aa",
    marginLeft: 10,
    marginTop: -30,
    marginBottom: -10,
  },
  viewMore: {
    color: "#20b2aa",
    fontWeight: "bold",
    marginTop: 16,
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
};
