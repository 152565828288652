import React, { useState, useEffect } from "react";
import { credentialAxios } from "utils/api-calls";
import { MyOrdersPage } from "pages/Orders/Orders";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertNumberToCurrency } from "utils/functions";
import styled from "styled-components";
import NoData from "pages/NoData/NoData";
import MobileNav from "components/common/MobileNav/MobileNav";
const OrderCategories = ({ transactionType }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await credentialAxios("/api/transaction/orders/");
        const data = response.data;

        if (data && data.results?.length) {
          setOrders(data.results);
        } else {
          setOrders([]);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setError(`Failed to fetch data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const filteredOrders =
    transactionType === "All"
      ? orders
      : orders
          .map((fundHouse) => ({
            ...fundHouse,
            transactions:
              fundHouse.transactions?.filter(
                (transaction) =>
                  transaction.transaction_type === transactionType
              ) || [],
          }))
          .filter((fundHouse) => fundHouse.transactions.length > 0);

  if (loading) {
    return (
      <Container>
        <AccordionSkeleton count={4} />
      </Container>
    );
  }

  if (error || (filteredOrders.length === 0 && transactionType === "All")) {
    return (
      <NoData
        message="You have no orders yet"
        subMessage="Start exploring Mutual Funds"
        redirecting="Explore Mutual Funds"
        onRedirect={() => navigate("/mutual-funds")}
      />
    );
  }

  if (filteredOrders.length === 0) {
    const noDataMessage =
      transactionType === "Redemption"
        ? "You have no redemptions yet."
        : "You have no purchases yet.";

    return <NoData message={noDataMessage} showButton={false} />;
  }

  return (
    <Container>
      <MobileNav headerTitle={"Orders"} heightBelow="0" />

      {filteredOrders.map((fundHouse) => (
        <AccordionForSections
          key={fundHouse.fund_house_id}
          title={fundHouse.fund_house_name}
        >
          <OrderDetails>
            <p>
              <strong>Total Amount:</strong>{" "}
              {convertNumberToCurrency(
                Number(parseFloat(fundHouse.total_amount).toFixed(2))
              )}
            </p>
          </OrderDetails>
          <MyOrdersPage
            data={fundHouse.transactions || []}
            navigateToDetailsPage={(orderNumber) =>
              navigate(`/orders/${orderNumber}`)
            }
            noDataMessage="You have no orders yet."
          />
        </AccordionForSections>
      ))}
    </Container>
  );
};

export default OrderCategories;

export const AccordionForSections = ({
  title = "",
  children,
  defaultExpanded = false,
}) => {
  const [open, setOpen] = useState(defaultExpanded);

  return (
    <AccordionContainer>
      <AccordionButton onClick={() => setOpen(!open)}>
        <span>{title}</span>
        {open ? (
          <KeyboardArrowUp fontSize="large" />
        ) : (
          <KeyboardArrowDown fontSize="large" />
        )}
      </AccordionButton>
      {open && <AccordionContent>{children}</AccordionContent>}
    </AccordionContainer>
  );
};

// Styled Components
const Container = styled.div`
  margin-top: 0;

  @media (max-width: 980px) {
    /* margin-top: 7rem; */
  }
`;

const Header = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const AccordionContainer = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
`;

const AccordionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--themeBluishGrey);
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: var(--themeColor);
  }

  span {
    text-align: left;
    flex-grow: 1;
    display: block;

    @media (max-width: 980px) {
      text-align: left;
    }
  }
`;

const AccordionContent = styled.div`
  padding: 8px 0;
`;

const OrderDetails = styled.div`
  padding: 10px 0;
  font-weight: bold;
`;

const AccordionSkeleton = ({ count = 4 }) => {
  return (
    <>
      {Array.from({ length: count }).map((_, index) => (
        <SkeletonContainer key={index}>
          <SkeletonHeader />
          <SkeletonContent />
        </SkeletonContainer>
      ))}
    </>
  );
};

const SkeletonContainer = styled.div`
  border: 1px solid #d3d3d3;
  padding: 16px;
  margin: 8px 0;
  border-radius: 4px;
`;

const SkeletonHeader = styled.div`
  height: 20px;
  width: 60%;
  background-color: #e0e0e0;
  margin-bottom: 16px;
  border-radius: 4px;
`;

const SkeletonContent = styled.div`
  height: 10px;
  width: 80%;
  background-color: #e0e0e0;
  margin-bottom: 8px;
  border-radius: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;
