import { Component } from "react";

class ConditionalMutateLoader extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error or perform other actions here
    console.error("Error caught in ConditionalMutateLoader:", error, errorInfo);
  }

  render() {
    const {
      conditions,
      renderInitial,
      renderLoading,
      renderError,
      renderSuccess,
      errorBoundaryFallback,
    } = this.props;

    if (this.state.hasError) {
      // Render the error boundary fallback component
      return errorBoundaryFallback || null;
    }

    if (conditions.isLoading) {
      return renderLoading ? renderLoading() : null;
    } else if (conditions.isError) {
      return renderError ? renderError(conditions.error) : null;
    } else if (conditions.isSuccess) {
      try {
        // Additional check for data existence before rendering fetched content
        return renderSuccess ? renderSuccess(conditions.isSuccess) : null;
      } catch (error) {
        // Catch any error that might occur during the rendering of fetched content
        console.error("Error rendering fetched content:", error);
        return errorBoundaryFallback || null;
      }
    } else {
      return renderInitial();
    }
  }
}

export default ConditionalMutateLoader;
